import React from 'react';

import useSwaggerUI from './useSwagger';
// import { PUBLIC_PRO_API_URL } from "../../api";

import './swagger.scss';

const Swagger = () => {
  useSwaggerUI();

  return <div id="swagger-ui" />;
};

// const Swagger = () => (
//   <iframe
//     title="Swagger"
//     src={`${PUBLIC_PRO_API_URL}/swagger/index.html`}
//     style={{ width: "100%", height: "100vh" }}
//   />
// );

export default Swagger;
