import { push } from 'connected-react-router';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import DesktopMenu from './components/DesktopMenu';
import HamburgerMenu from './components/HamburgerMenu';

import myUplinkLogo from '../../../../assets/img/myuplinkdevs.png';
import { logoutUser } from '../../../../pages/Login/actions';
import { Permissions } from '../../../AppInitializer/reducers';

import { PUBLIC_API_URL } from '../../../../api/client';
import profileIcon from '../../../../assets/img/profile.svg';
import { ROUTES } from '../../../../constants';
import './topmenu.scss';

const profileMenu = [
  {
    name: 'Profile settings',
    id: 'profile-settings.title',
    path: ROUTES.PROFILE_SETTINGS,
  },
  {
    name: 'Log out',
    id: 'menu.logout',
    path: ROUTES.LOGOUT,
  },
];

const MenuLayout = ({ permissions }) =>
  [
    {
      name: 'Public API Documentation',
      id: 'menu.documentation',
      align: 'left',
      path: ROUTES.DOCUMENTATION.ROOT,
      children: [
        {
          name: 'Introduction',
          id: 'menu.documentation.introduction',
          path: ROUTES.DOCUMENTATION.INTRO,
        },
        //Hiding because some links for tutorials are not ready yet but will be use in future
        // {
        //   name: 'Tutorial',
        //   id: 'menu.documentation.tutorial',
        //   path: '/tutorial'
        // },
        {
          name: 'Authentication',
          id: 'menu.documentation.authentication',
          path: ROUTES.DOCUMENTATION.AUTH,
        },
        {
          name: 'Parameters',
          id: 'menu.documentation.parameters',
          path: ROUTES.DOCUMENTATION.PARAMETERS,
        },
        {
          name: 'Swagger',
          id: 'menu.documentation.swagger',
          url: `${PUBLIC_API_URL}/swagger/index.html`,
        },
        {
          name: 'Swagger Extended',
          id: 'menu.documentation.swagger-extended',
          hidden: !permissions[Permissions.extendedApi],
          url: `${PUBLIC_API_URL}/swagger/extended/index.html`,
        },
        {
          name: 'Swagger Misc',
          id: 'menu.documentation.swagger-misc',
          hidden: !permissions[Permissions.miscellaneousApi],
          url: `${PUBLIC_API_URL}/swagger/misc/index.html`,
        },
        {
          name: 'Changelog',
          id: 'menu.documentation.updates',
          path: ROUTES.DOCUMENTATION.UPDATES,
        },
      ].filter(i => !i.hidden),
    },
    {
      name: 'PRO API Documentation',
      id: 'menu.pro-documentation',
      hidden: false,
      align: 'left',
      path: ROUTES.PRO_DOCUMENTATION.ROOT,
      children: [
        {
          name: 'Introduction',
          id: 'menu.pro-documentation.introduction',
          path: ROUTES.PRO_DOCUMENTATION.INTRO,
        },
        //Hiding because some links for tutorials are not ready yet but will be use in future
        // {
        //   name: 'Tutorial',
        //   id: 'menu.documentation.tutorial',
        //   path: '/tutorial'
        // },
        {
          name: 'Authentication',
          id: 'menu.pro-documentation.authentication',
          path: ROUTES.PRO_DOCUMENTATION.AUTH,
        },
        {
          name: 'Parameters',
          id: 'menu.pro-documentation.parameters',
          path: ROUTES.PRO_DOCUMENTATION.PARAMETERS,
        },
        {
          name: 'Swagger',
          id: 'menu.pro-documentation.swagger',
          // url: `${PUBLIC_API_URL}/swagger/index.html`,
          url: ROUTES.PRO_DOCUMENTATION.SWAGGER,
        },
        {
          name: 'Changelog',
          id: 'menu.pro-documentation.updates',
          path: ROUTES.PRO_DOCUMENTATION.UPDATES,
        },
      ].filter(i => !i.hidden),
    },
    {
      name: 'Apps',
      id: 'menu.apps',
      align: 'left',
      path: ROUTES.APPLICATIONS,
    },
    {
      name: 'Profile',
      id: 'menu.headers.profile',
      align: 'right',
      useIcon: true,
      icon: profileIcon,
      children: profileMenu,
    },
  ].filter(i => !i.hidden);

const Logo = () => (
  <div className="logo">
    <Link to={'/'}>
      <img src={myUplinkLogo} alt="myUplinkDevs" />
    </Link>
  </div>
);

class TopMenu extends Component {
  static propTypes = {
    userInfo: PropTypes.object,
    appPermissions: PropTypes.object,

    logoutUser: PropTypes.func.isRequired,
    push: PropTypes.func.isRequired,
  };

  _onLogOut = () => {
    this.props.logoutUser();
    this.props.push('/login');
  };

  render() {
    const menuItems = MenuLayout({
      onLogOut: this._onLogOut,
      permissions: this.props.appPermissions || {},
    });

    return (
      <header className="header-container">
        <div className="header-container-content">
          <Logo />
          <DesktopMenu menuItems={menuItems} />
          <div className="mobileMenu">
            <HamburgerMenu menuItems={menuItems} />
          </div>
        </div>
      </header>
    );
  }
}

export default connect(
  ({ app: { userInfo, appPermissions } }) => ({
    userInfo,
    appPermissions,
  }),
  { logoutUser, push },
)(TopMenu);
