import React from 'react';
import { FormattedHTMLMessage } from 'react-intl';
import BaseLegalPage from './BaseLegalPage';

const TermsOfService = props => (
  <BaseLegalPage
    {...props}
    content={<FormattedHTMLMessage id="terms-of-service" />}
  />
);

export default TermsOfService;
