import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { FormattedMessage } from 'react-intl';
import './title.scss';

const Title = ({
  titleTranslationId,
  defaultMessage,
  description,
  className,
  children,
}) => (
  <h1 className={classNames('titleComponent', className)}>
    <FormattedMessage
      id={titleTranslationId}
      defaultMessage={defaultMessage}
      description={description}
    />
    {children}
  </h1>
);

Title.propTypes = {
  titleTranslationId: PropTypes.string.isRequired,
  defaultMessage: PropTypes.string,
  description: PropTypes.string,
  className: PropTypes.string,
  children: PropTypes.any,
};

Title.defaultProps = {
  className: '',
};

export default Title;
