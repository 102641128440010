import { getAzureB2CLogoutLink } from '../../api/azureB2CService';
import {
  deleteAuthTokens,
  isAzureB2CAuth,
  putAuthTokens,
  removeAzureB2CPolicyName,
} from '../../localStorage';

import {
  AUTH,
  AUTH_FAIL,
  AUTH_SUCCESS,
  LOGOUT_USER,
  VALIDATE_CREDENTIALS,
  VALIDATE_CREDENTIALS_FAIL,
  VALIDATE_CREDENTIALS_SUCCESS,
} from './actions';

const initialState = {
  loading: false,
  error: null,
  acceptedAgreements: null,
  modalError: null,
};

const actionHandlers = {
  [VALIDATE_CREDENTIALS](state) {
    return { ...state, loading: true, error: null };
  },
  [VALIDATE_CREDENTIALS_SUCCESS](state, action) {
    return {
      ...state,
      loading: false,
      acceptedAgreements: action.payload.data,
      modalError: null,
      error: null,
    };
  },
  [VALIDATE_CREDENTIALS_FAIL](state, action) {
    return { ...state, loading: false, error: action.error };
  },

  [AUTH](state) {
    return { ...state, loading: true };
  },
  [AUTH_SUCCESS](state, action) {
    putAuthTokens(action.payload.data);
    return { ...state, loading: false, modalError: null };
  },
  [AUTH_FAIL](state, action) {
    return { ...state, loading: false, error: action.error.description };
  },

  [LOGOUT_USER](state) {
    const isAzureB2C = isAzureB2CAuth();
    deleteAuthTokens();

    if (isAzureB2C) {
      const logoutLink = getAzureB2CLogoutLink();
      removeAzureB2CPolicyName();
      window.location.href = logoutLink;
    }
    return { ...state, error: '' };
  },
};

export default function reducer(state = initialState, action) {
  const { type } = action;
  const actionHandler = actionHandlers[type];
  if (actionHandler) {
    return actionHandler(state, action);
  }

  return state;
}
