import { EventEmitter } from 'events';

const inApp = {
  auth: {
    // TOKEN_EXPIRED: "TOKEN_EXPIRED",
    // LOGOUT: "LOGOUT",
    // LOGIN: "LOGIN",
  },
  routing: {
    CHANGE_TAB: 'CHANGE_TAB',
  },
};

export const eventEmitter = new EventEmitter();

export default {
  inApp,
};
