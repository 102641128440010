import React from 'react';
import PropTypes from 'prop-types';

import './spinner.scss';

const Spinner = ({ dark, absoluteCenter }) => (
  <div
    className={
      'sk-circle' +
      (dark ? ' sk-circle--dark' : '') +
      (absoluteCenter ? ' sk-circle--absoluteCenter' : '')
    }
  >
    <div className="sk-circle1 sk-child"></div>
    <div className="sk-circle2 sk-child"></div>
    <div className="sk-circle3 sk-child"></div>
    <div className="sk-circle4 sk-child"></div>
    <div className="sk-circle5 sk-child"></div>
    <div className="sk-circle6 sk-child"></div>
    <div className="sk-circle7 sk-child"></div>
    <div className="sk-circle8 sk-child"></div>
    <div className="sk-circle9 sk-child"></div>
    <div className="sk-circle10 sk-child"></div>
    <div className="sk-circle11 sk-child"></div>
    <div className="sk-circle12 sk-child"></div>
  </div>
);

Spinner.defaultProps = {
  dark: false,
  absoluteCenter: false,
};

Spinner.propTypes = {
  dark: PropTypes.bool,
  absoluteCenter: PropTypes.bool,
};

export default Spinner;
