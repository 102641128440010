import { push } from 'connected-react-router';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';

import { getRedirectLocation } from '../../utils';
import { login, validateUserCredentials } from './actions';
import LoginForm from './LoginForm';

import LoginAB2C from './LoginAB2C';

import { isAzureB2CEnabled } from '../../components/constants/constants';
import './login.scss';

export const Login = ({
  login,
  location,
  push,
  error,
  loading,
  validateUserCredentials,
}) => {
  const onSubmit = async (values, { setSubmitting }) => {
    setSubmitting(true);
    const username = values.username.trim();
    const password = values.password.trim();
    const validateUserCredentialsResult = await validateUserCredentials(
      username,
      password,
    );

    if (validateUserCredentialsResult.payload) {
      await login(username, password);
      const redirectLocation = getRedirectLocation(location);
      push(redirectLocation);
      return;
    }

    setSubmitting(false);
  };

  return (
    <div className="Login">
      {!isAzureB2CEnabled ? (
        <LoginForm
          push={push}
          onSubmit={onSubmit}
          requestError={error ? error.response.data.description : ''}
          isSubmitting={loading}
        />
      ) : (
        <LoginAB2C />
      )}
    </div>
  );
};

Login.propTypes = {
  push: PropTypes.func.isRequired,
  location: PropTypes.object.isRequired,
  error: PropTypes.string,
  validateUserCredentials: PropTypes.func.isRequired,
  login: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
};

export default connect(
  ({ login, register }) => ({
    ...login,
    register,
  }),
  {
    push,
    login,
    validateUserCredentials,
  },
)(Login);
