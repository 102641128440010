import { useEffect } from 'react';
import { PUBLIC_PRO_API_URL } from '../../api/client';

const createSwaggerUIBundleScript = () => {
  const script = document.createElement('script');
  script.src = 'https://unpkg.com/swagger-ui-dist@5.11.0/swagger-ui-bundle.js';
  script.async = true;
  script.crossOrigin = 'anonymous';
  document.body.appendChild(script);

  const link = document.createElement('link');
  link.rel = 'stylesheet';
  link.href = 'https://unpkg.com/swagger-ui-dist@5.11.0/swagger-ui.css';
  document.head.appendChild(link);

  return { script, link };
};

const useSwaggerUI = () => {
  useEffect(() => {
    const { script, link } = createSwaggerUIBundleScript();

    // TODO: Check if removal is needed
    return () => {
      document.body.removeChild(script);
      document.head.removeChild(link);
    };
  }, []);

  useEffect(() => {
    const checkSwaggerUI = () => {
      if (window.swaggerUi) {
        return;
      }

      if (!window.SwaggerUIBundle) {
        const timerId = setTimeout(checkSwaggerUI, 500);
        return () => clearTimeout(timerId);
      }

      window.swaggerUi = window.SwaggerUIBundle({
        url: `${PUBLIC_PRO_API_URL}/swagger/docs/public-v2/swagger.json`,
        dom_id: '#swagger-ui',
      });
    };

    checkSwaggerUI();
  }, []);
};

export default useSwaggerUI;
