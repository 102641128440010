import PropTypes from 'prop-types';
import React from 'react';

import { NavigationItem } from './NavigationItem';

import { WEB_DEV_URL, WEB_PRO_URL, WEB_URL } from '../../../../api/client';

import './navigation.scss';

const Navigation = ({ url }) => {
  return (
    <nav role="navigation" className="navigation">
      <ul className="navigation-list">
        <NavigationItem
          href={url && WEB_URL}
          id="unauth.menu.private"
          defaultMessage="Consumers"
        />
        <NavigationItem
          href={url && WEB_PRO_URL}
          id="unauth.menu.professional"
          defaultMessage="Professionals"
        />
        <NavigationItem
          className="active"
          href={url && WEB_DEV_URL}
          id="unauth.menu.developer"
          defaultMessage="Developers"
        />
      </ul>
    </nav>
  );
};

Navigation.propTypes = {
  url: PropTypes.object.isRequired,
};

export default Navigation;
