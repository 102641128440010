import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { NotificationManager } from 'react-notifications';
import { sendConfirmationDeleteUser } from '../../../../api';
import RemoveConfirmation from '../RemoveConfirmation';
import DeleteAccountForm from './DeleteAccountForm';

class DeleteAccountTab extends React.Component {
  state = {
    showRemovePopup: false,
    isSubmitting: false,
    password: '',
  };

  onRemoveConfirm = async () => {
    this.props.resetDeleteError();
    this.setState({ ...this.state, isSubmitting: true });
    this.props.changeRedirectStatus(false);

    if (await this.props.deleteUser(this.props.userId, this.state.password)) {
      this.setState({
        ...this.state,
        isSubmitting: false,
        showRemovePopup: false,
      });
    }
  };

  onRemoveAbort = () => {
    this.setState({ ...this.state, showRemovePopup: false });
  };

  onRemoveButtonClick = async (values, { setSubmitting }) => {
    setSubmitting(true);

    const { userEmail, validateUserCredentials } = this.props;
    const password = values.password.trim();
    if (await validateUserCredentials(userEmail, password)) {
      this.setState({ ...this.state, showRemovePopup: true });
      this.setState({ ...this.state, password });
    }

    setSubmitting(false);
  };

  handleSendConfirmationLink = async () => {
    try {
      this.setState({ ...this.state, isSubmitting: true });
      const response = await sendConfirmationDeleteUser();

      if (response.status === 202) {
        NotificationManager.success(
          <FormattedMessage
            id="sendConfirmationNotificationSuccess.description"
            defaultMessage="We have sent you a confirmation e-mail on the address specified in profile details."
          />,
          <FormattedMessage
            id="sendConfirmationNotificationSuccess.title"
            defaultMessage="E-mail was sent"
          />,
        );
      }
    } catch (error) {
      if (error.response.status === 429) {
        NotificationManager.error(
          <FormattedMessage
            id="user.delete.error.request.limit.reached"
            defaultMessage="You've reached the limit of 3 requests in 24 hours. Please wait until this period ends to request again. Remember to check your spam or junk folder for our emails."
          />,
        );
      } else {
        NotificationManager.error(
          <FormattedMessage
            id="server.error.title"
            defaultMessage="Server error"
          />,
        );
      }
    } finally {
      this.setState({ ...this.state, isSubmitting: false });
    }
  };

  render() {
    const { deleteError, validatePasswordError } = this.props;
    return (
      <div>
        <div className="remove-user-account">
          <FormattedMessage
            id="label.system-profile.delete-account"
            defaultMessage="Remove Your Account"
          />
        </div>
        <DeleteAccountForm
          onSubmit={this.onRemoveButtonClick}
          isSubmitting={this.state.isSubmitting}
          requestError={validatePasswordError || deleteError}
          handleSendConfirmationForDelete={this.handleSendConfirmationLink}
        />
        {this.state.showRemovePopup && (
          <RemoveConfirmation
            isShown={this.state.showRemovePopup}
            onConfirm={this.onRemoveConfirm}
            onDiscard={this.onRemoveAbort}
            requestError={deleteError}
            isSubmitting={this.state.isSubmitting}
          />
        )}
      </div>
    );
  }
}

DeleteAccountTab.propTypes = {
  deleteUser: PropTypes.func.isRequired,
  userId: PropTypes.string.isRequired,
  userEmail: PropTypes.string.isRequired,
  resetDeleteError: PropTypes.func.isRequired,
  history: PropTypes.object,
  deleteError: PropTypes.any,
  validateUserCredentials: PropTypes.func.isRequired,
  validatePasswordError: PropTypes.any,
  changeRedirectStatus: PropTypes.func.isRequired,
};

export default DeleteAccountTab;
