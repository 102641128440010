import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Link } from '../../../../../../../Link';
import { FormattedMessage } from 'react-intl';
import './hamburger-menu-item.scss';

class MenuItem extends Component {
  state = {
    hover: false,
  };

  handleHover() {
    this.setState({ hover: !this.state.hover });
  }

  handleClick(e, path) {
    this.props.onLinkClick(e, path);
  }

  render() {
    const { id } = this.props;

    if (id === 'menu.language') {
      return (
        <div className="hamburgerMenuItem--languageSelector hamburgerMenuItem">
          <div className="hamburgerMenuItem__menuItemList">
            <div className="hamburgerMenuItem__menuItemHeading">
              <FormattedMessage
                id={this.props.id}
                defaultMessage={this.props.name}
              />
            </div>
          </div>
        </div>
      );
    }

    return (
      <div className="hamburgerMenuItem">
        <div className="hamburgerMenuItem__menuItemList">
          <div
            className="hamburgerMenuItem__menuItemHeading"
            onClick={this.props.action}
          >
            <Link
              key={this.props.id}
              className="hamburgerMenuItem__menuItemHeadingLink"
              to={this.props.path}
            >
              <FormattedMessage
                id={this.props.id}
                defaultMessage={this.props.name}
              />
            </Link>
          </div>
          {this.props.children &&
            this.props.children
              .filter(item => !item.hidden)
              .map((val, index) => (
                <div className="hamburgerMenuItem__menuItem" key={index}>
                  {val.url ? (
                    <div
                      key={val.id}
                      className="hamburgerMenuItem__menuItemLink"
                    >
                      <a className="url" href={val.url}>
                        <FormattedMessage
                          id={val.id}
                          defaultMessage={val.name}
                        />
                      </a>
                    </div>
                  ) : val.disabled ? (
                    <div
                      key={val.id}
                      className="hamburgerMenuItem__menuItemLink-disabled"
                    >
                      <FormattedMessage id={val.id} defaultMessage={val.name} />
                    </div>
                  ) : (
                    <Link
                      className="hamburgerMenuItem__menuItemLink"
                      to={val.path}
                      onClick={e => {
                        this.handleClick(e, val.path);
                      }}
                    >
                      <FormattedMessage id={val.id} defaultMessage={val.name} />
                    </Link>
                  )}
                </div>
              ))}
        </div>
      </div>
    );
  }
}

MenuItem.propTypes = {
  name: PropTypes.string,
  id: PropTypes.string,
  path: PropTypes.string,
  action: PropTypes.func,
  onLinkClick: PropTypes.func,
  children: PropTypes.any,
};

export default MenuItem;
