import React from 'react';
import { FormattedHTMLMessage } from 'react-intl';

import GenericDocument from '../GenericDocument';
import enterBearerTokenImg from '../../../../assets/img/enter-bearer-token.png';
import executeImg from '../../../../assets/img/execute.png';
import tryItOutImg from '../../../../assets/img/try-it-out.png';

import './quick-start-guide.scss';

const QuickStartGuide = ({ isPro }) =>
  isPro ? (
    <p>
      <FormattedHTMLMessage
        id="pro.wiki.quick-start-guide-pt1"
        defaultMessage="Document is temporary unavailable."
      />
      <div className="quick-start-guide-image-wrapper">
        <img src={enterBearerTokenImg} />
      </div>
      <FormattedHTMLMessage
        id="pro.wiki.quick-start-guide-pt2"
        defaultMessage="Document is temporary unavailable."
      />
      <div className="quick-start-guide-image-wrapper">
        <img src={tryItOutImg} />
      </div>
      <FormattedHTMLMessage
        id="pro.wiki.quick-start-guide-pt3"
        defaultMessage="Document is temporary unavailable."
      />
      <div className="quick-start-guide-image-wrapper">
        <img src={executeImg} />
      </div>
      <FormattedHTMLMessage
        id="pro.wiki.quick-start-guide-pt4"
        defaultMessage="Document is temporary unavailable."
      />
    </p>
  ) : (
    <GenericDocument id="wiki.quick-start-guide" />
  );

export default QuickStartGuide;
