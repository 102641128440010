import React from 'react';
import { FormattedMessage } from 'react-intl';

import {
  getActiveTabFromQueryString,
  getDocumentNameIdPrefix,
  selectTab as selectTabViaURL,
} from '../../utils';
import GenericDocument from '../Intro/components/GenericDocument';
import PageContentWrapper from '../PageContentWrapper';
import useSelectedTab from '../hooks/useSelectedTab';

import 'react-tabs/style/react-tabs.css';
import './auth.scss';

export const AUTH_TABS = {
  AUTH: 'auth',
  CLIENT_REGISTRATION: 'client-registration',
  CLIENT_AUTH: 'client-auth',
};

const Auth = ({ location }) => {
  const selectedTab = getActiveTabFromQueryString(location);

  const { isLoadingTab, setIsLoadingTab } = useSelectedTab(
    selectedTab,
    AUTH_TABS.AUTH,
    AUTH_TABS,
  );

  const selectTab = index => {
    setIsLoadingTab(true);
    selectTabViaURL(index);
  };

  const getCurrentTab = () => {
    const prefix = getDocumentNameIdPrefix(location?.pathname ?? '');
    switch (selectedTab) {
      case AUTH_TABS.AUTH:
        return <GenericDocument id={`${prefix}wiki.auth.step1`} />;
      case AUTH_TABS.CLIENT_REGISTRATION:
        return <GenericDocument id={`${prefix}wiki.auth.step2`} />;
      case AUTH_TABS.CLIENT_AUTH:
        return <GenericDocument id={`${prefix}wiki.auth.step3`} />;
      default:
        return null;
    }
  };

  return (
    <div className="auth">
      <div className="row">
        <div className="tabordion" tabIndex="-1">
          <section id="auth-section" tabIndex="0">
            <div
              onClick={() => selectTab(AUTH_TABS.AUTH)}
              tabIndex="0"
              className={
                selectedTab === AUTH_TABS.AUTH
                  ? 'option-label selected'
                  : 'option-label'
              }
            >
              <FormattedMessage
                id={'label.auth'}
                defaultMessage="Authentication"
              />
            </div>
            <div
              onClick={() => selectTab(AUTH_TABS.CLIENT_REGISTRATION)}
              tabIndex="0"
              className={
                selectedTab === AUTH_TABS.CLIENT_REGISTRATION
                  ? 'option-label selected'
                  : 'option-label'
              }
            >
              <FormattedMessage
                id={'label.client-registration'}
                defaultMessage="Client Registration"
              />
            </div>
            <div
              onClick={() => selectTab(AUTH_TABS.CLIENT_AUTH)}
              tabIndex="0"
              className={
                selectedTab === AUTH_TABS.CLIENT_AUTH
                  ? 'option-label selected'
                  : 'option-label'
              }
            >
              <FormattedMessage
                id={'label.client-auth'}
                defaultMessage="Client Authentication / Authorization"
              />
            </div>
          </section>
          <div className="tab-content" tabIndex="0">
            <PageContentWrapper
              isLoadingTab={isLoadingTab}
              location={location}
              availableTabs={AUTH_TABS}
            >
              {getCurrentTab()}
            </PageContentWrapper>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Auth;
