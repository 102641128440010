import * as api from '../../api';
import { WEB_URL } from '../../api/client';
import { getAuthTokens } from '../../localStorage';

export const USER_AUTH_STATUS_CHANGED = 'app/USER_AUTH_STATUS_CHANGED';

export const initUser = () => {
  const tokens = getAuthTokens();
  const url = {
    WEB_URL,
  };
  return {
    type: USER_AUTH_STATUS_CHANGED,
    loggedIn: !!tokens,
    url: url,
  };
};

export const GET_USER_INFO_REQUEST = 'app/GET_USER_INFO_REQUEST';
export const GET_USER_INFO_RESPONSE = 'app/GET_USER_INFO_RESPONSE';
export const getUserInfo = () => async dispatch => {
  dispatch({ type: GET_USER_INFO_REQUEST });

  const response = await api.getUserInfo();
  if (response.status === 200) {
    dispatch({ type: GET_USER_INFO_RESPONSE, userInfo: response.data });
  }
};

export const GET_USER_ACTIVE_SERVICE_PARTNER_REQUEST =
  'app/GET_USER_ACTIVE_SERVICE_PARTNER_REQUEST';
export const GET_USER_ACTIVE_SERVICE_PARTNER_RESPONSE =
  'app/GET_USER_ACTIVE_SERVICE_PARTNER_RESPONSE';
export const getUserActiveServicePartner = () => async dispatch => {
  dispatch({ type: GET_USER_ACTIVE_SERVICE_PARTNER_REQUEST });

  const response = await api.getUserActiveServicePartner();
  if (response.status === 200) {
    dispatch({
      type: GET_USER_ACTIVE_SERVICE_PARTNER_RESPONSE,
      hasActiveSP: response.data?.length > 0,
    });
  }
};

export const SET_APP_LOADED_STATUS = 'app/SET_APP_LOADED_STATUS';
export const setAppLoadedStatus = status => ({
  type: SET_APP_LOADED_STATUS,
  status,
});

export const CHANGE_REDIRECT_STATUS = 'app/CHANGE_REDIRECT_STATUS';
export const changeRedirectStatus = status => ({
  type: CHANGE_REDIRECT_STATUS,
  status,
});

export const GET_COUNTRIES_REQUEST = 'app/GET_COUNTRIES_REQUEST';
export const GET_COUNTRIES_RESPONSE = 'app/GET_COUNTRIES_RESPONSE';
export const getCountries = () => async dispatch => {
  dispatch({ type: GET_COUNTRIES_REQUEST });

  const res = await api.getCountriesList();
  const countries = res.data;
  dispatch({ type: GET_COUNTRIES_RESPONSE, countries: countries });
};
