import React from 'react';
import { Breadcrumb } from 'react-bootstrap';
import {
  isSelectedTabInAvailableTabs,
  isSelectedTabInExistingSlugs,
  parseLocationSlugToWords,
} from '../../utils';

const Breadcrumbs = ({ path, availableTabs }) => {
  const { slugs, activeTab } = path;

  return (
    <Breadcrumb>
      {slugs.map((slug, index) => {
        // const isActive = index === slugs.length - 1;
        return (
          <Breadcrumb.Item
            key={slug}
            active={true}
            // href={
            //   isActive ? undefined : `/${slugs.slice(0, index + 1).join("/")}`
            // }
          >
            {parseLocationSlugToWords(slug)}
          </Breadcrumb.Item>
        );
      })}
      {isSelectedTabInAvailableTabs(activeTab, availableTabs) &&
        !isSelectedTabInExistingSlugs(activeTab, slugs) && (
          <Breadcrumb.Item active={true}>
            {parseLocationSlugToWords(activeTab)}
          </Breadcrumb.Item>
        )}
    </Breadcrumb>
  );
};

export default Breadcrumbs;
