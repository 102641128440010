import {
  deleteAuthTokens,
  getAuthTokens,
  isAzureB2CAuth,
  putAuthTokens,
} from '../localStorage';
import { logoutUser, reissueAuthToken } from '../pages/Login/actions';
import { getAzureB2CLoginLink, reissueAzureB2CTokens } from './azureB2CService';
import { API } from './client';

export const DEFAULT_LANGUAGE = 'en-US';

const handleAzureB2CRefresh = async () => {
  deleteAuthTokens();
  const redirectLink = await getAzureB2CLoginLink({
    redirectTo: window.location.pathname + (window.location.search || ''),
  });

  window.location.href = redirectLink;
};

const refreshTokens = async refreshToken => {
  return isAzureB2CAuth()
    ? await reissueAzureB2CTokens(refreshToken)
    : await reissueAuthToken(refreshToken);
};

const handleAuthError = async (store, error) => {
  const { refresh_token } = getAuthTokens();
  try {
    const authResponse = await refreshTokens(refresh_token);
    putAuthTokens(authResponse.data);
    return API(error.config);
  } catch (e) {
    if (isAzureB2CAuth()) {
      const { selectedLanguage } = store.getState().language;
      return handleAzureB2CRefresh(selectedLanguage);
    }
    store.dispatch(logoutUser());
    return Promise.reject(error);
  }
};

export const registerAuthHeaderInterceptor = store => {
  API.interceptors.request.use(config => {
    const tokens = getAuthTokens();
    if (tokens) {
      config.headers.Authorization = `Bearer ${tokens.access_token}`;
    }

    const { selectedLanguage = DEFAULT_LANGUAGE } = store.getState().language;
    config.headers['Accept-Language'] = selectedLanguage;

    return config;
  });

  API.interceptors.response.use(
    response => response,
    async error => {
      if (error.response?.status === 401) {
        return handleAuthError(store, error);
      }

      if (!error.response.data) {
        error.response.data = {};
      }
      return Promise.reject(error);
    },
  );
};
