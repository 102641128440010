import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { push } from 'connected-react-router';

class Link extends Component {
  render() {
    const { children, to, goToPage, className } = this.props;

    return (
      <div className={className} onClick={() => goToPage(to)}>
        {children}
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => {
  return {
    goToPage: path => {
      dispatch(push(path));
    },
  };
};

Link.propTypes = {
  to: PropTypes.string,
  goToPage: PropTypes.func,
  children: PropTypes.any,
  className: PropTypes.string,
};

export default connect(null, mapDispatchToProps)(Link);
