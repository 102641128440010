export const configNames = {
  Local: 'Local',
  LocalHost: 'localhost',
  DevCloud: 'Dev-Cloud',
  TestCloud: 'Test-Cloud',
  ProdCloud: 'Prod-Cloud',
};

export const envNames = {
  LOCALHOST: 'localhost',
  DEVELOPMENT: 'development',
  STAGING: 'staging',
  PRODUCTION: 'production',
};

const areWeTestingWithJest = () => {
  return process.env.JEST_WORKER_ID !== undefined;
};

export const getConfig = () => {
  return process.env;
};

export const getConfigName = () => {
  if (areWeTestingWithJest()) return configNames.Local;
  return process.env.REACT_APP_ENV;
};

export const isProdEnv = () =>
  process.env.REACT_APP_ENV === envNames.PRODUCTION;

export const isDevelopmentEnv = () =>
  process.env.REACT_APP_ENV === envNames.LOCALHOST ||
  process.env.REACT_APP_ENV === envNames.STAGING ||
  process.env.REACT_APP_ENV === envNames.DEVELOPMENT;

export const AzureB2CConfig = {
  TENANT_ID: process.env.REACT_APP_AZURE_B2C_TENANT_ID || '',
  CLIENT_ID: process.env.REACT_APP_AZURE_B2C_CLIENT_ID || '',
  MY_UPLINK_CLIENT_ID: process.env.REACT_APP_CLIENT_ID || '',
  AZURE_B2C_HOST_NAME: process.env.REACT_APP_AZURE_B2C_HOST_NAME || '',
  POLICIES: {
    LOGIN: 'B2C_1A_LOGIN_FLOW_EMAIL_CONSUMERS',
    PASSWORD_CHANGE: 'B2C_1A_PASSWORDCHANGE',
  },
  AUTH_OPTIONS: {
    SIGN_IN: 'signin',
    SIGN_UP: 'signup',
  },
};
