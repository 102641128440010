import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Dropdown as ReactDropDown, MenuItem } from 'react-bootstrap';
import './Dropdown.scss';

class Dropdown extends React.Component {
  static propTypes = {
    id: PropTypes.string.isRequired,
    items: PropTypes.oneOfType([
      PropTypes.arrayOf(PropTypes.object),
      PropTypes.arrayOf(PropTypes.string),
    ]),
    onSelect: PropTypes.func.isRequired,
    labelGetter: PropTypes.func,
    tooltipGetter: PropTypes.func,
    selectedItem: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    placeholder: PropTypes.string,
    disabled: PropTypes.bool,
    ignoreInternalState: PropTypes.bool,
    className: PropTypes.string,
    isItemNameMax: PropTypes.bool,
  };
  state = {
    selectedItem: null,
  };

  componentDidMount() {
    this.setState({
      ...this.state,
      selectedItem: this.props.selectedItem || null,
    });
  }

  selectItem = (i, e) => {
    this.props.onSelect(i, e);
    this.setState({ ...this.state, selectedItem: i });
  };

  render() {
    const labelGetter = this.props.labelGetter || (e => e.text);
    const tooltipGetter = this.props.tooltipGetter || (e => e.tooltip);
    const selectedItem = this.props.ignoreInternalState
      ? this.props.selectedItem
      : this.props.selectedItem || this.state.selectedItem;

    return (
      <ReactDropDown id={this.props.id} disabled={this.props.disabled}>
        <ReactDropDown.Toggle
          className={classNames('dropdown-button', this.props.className || '')}
        >
          <span
            title={selectedItem && tooltipGetter(selectedItem)}
            className="textWrapper"
          >
            {selectedItem
              ? labelGetter(selectedItem)
              : this.props.placeholder
                ? this.props.placeholder
                : ''}
          </span>
        </ReactDropDown.Toggle>
        <ReactDropDown.Menu>
          {this.props.items.map((item, i) => (
            <MenuItem
              title={item && tooltipGetter(item)}
              key={i}
              id={this.props.id}
              onClick={e => this.selectItem(item, e)}
            >
              {labelGetter(item)}
            </MenuItem>
          ))}
        </ReactDropDown.Menu>
      </ReactDropDown>
    );
  }
}

export default Dropdown;
