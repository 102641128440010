import React from 'react';
import { FormattedHTMLMessage } from 'react-intl';
import BaseLegalPage from './BaseLegalPage';

const PrivacyPolicy = props => (
  <BaseLegalPage
    {...props}
    content={<FormattedHTMLMessage id="privacy-policy" />}
  />
);

export default PrivacyPolicy;
