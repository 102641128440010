import 'react-app-polyfill/ie9';

import React from 'react';
import ReactDOM from 'react-dom';

import { history } from './store/configureStore';
import store from './store';

import Root from './Root';

import './styles/styles.scss';

import { registerAuthHeaderInterceptor } from './api/requestInterceptor';

require('./assets/img/favicon.ico');

const container = document.getElementById('root');

registerAuthHeaderInterceptor(store);

ReactDOM.render(<Root store={store} history={history} />, container);
