import React from 'react';

import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { compose } from 'redux';

import { NotificationManager } from 'react-notifications';
import 'react-notifications/lib/notifications.css';

import { getApps } from '../../pages/Apps/reducers';
import {
  getCountries,
  getUserActiveServicePartner,
  getUserInfo,
  initUser,
  setAppLoadedStatus,
} from './actions';

import Spinner from '../../components/Spinner';

import { preventGoogleFontsLoading } from './utils';

class AppInitializer extends React.Component {
  static propTypes = {
    children: PropTypes.oneOfType([
      PropTypes.element,
      PropTypes.arrayOf(PropTypes.element),
    ]).isRequired,

    initUser: PropTypes.func.isRequired,
    getUserInfo: PropTypes.func.isRequired,
    setAppLoadedStatus: PropTypes.func.isRequired,
    getApps: PropTypes.func.isRequired,
    getUserActiveServicePartner: PropTypes.func,
    getCountries: PropTypes.func,

    userLoggedIn: PropTypes.bool,
    userInfo: PropTypes.object,
    appLoaded: PropTypes.bool,
    countries: PropTypes.arrayOf(PropTypes.object),
  };

  static defaultProps = {
    userLoggedIn: false,
  };

  state = {
    appLoading: true,
  };

  async componentDidMount() {
    try {
      preventGoogleFontsLoading();
      await this.props.initUser();
      await this.props.getCountries();

      // Only load user-specific data if user is already logged in
      if (this.props.userLoggedIn) {
        await this.loadUserData();
      }

      await this.props.setAppLoadedStatus(true);
      this.setState({ appLoading: false });
    } catch (error) {
      NotificationManager.error(
        <FormattedMessage
          id="generic.error.request.unknown"
          defaultMessage="An error has occurred. Try again later."
        />,
      );
    }
  }

  loadUserData = async () => {
    try {
      await this.props.getUserInfo();
      await this.props.getUserActiveServicePartner();
      await this.props.getApps();
    } catch (error) {
      NotificationManager.error(
        <FormattedMessage
          id="generic.error.request.unknown"
          defaultMessage="An error has occurred. Try again later."
        />,
      );
    }
  };

  async componentDidUpdate(prevProps) {
    if (
      this.props.userLoggedIn &&
      prevProps.userLoggedIn !== this.props.userLoggedIn
    ) {
      await this.loadUserData();
    }
  }

  render() {
    if (!this.props.appLoaded || this.state.appLoading) {
      return <Spinner dark />;
    }

    return this.props.children;
  }
}

const withConnect = connect(({ app }) => ({ ...app }), {
  initUser,
  getUserInfo,
  getUserActiveServicePartner,
  setAppLoadedStatus,
  getCountries,

  getApps,
});

export default compose(withRouter, withConnect)(AppInitializer);
