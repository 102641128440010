import React from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
import Button from '../../../components/Button';

const ExpireClientSecretConfirmation = ({
  isShown,
  onConfirm,
  onCancel,
  isDisabled,
}) => {
  return (
    <Modal show={isShown} onHide={onCancel}>
      <Modal.Header>
        <Modal.Title>
          <FormattedMessage id="confirm" defaultMessage="Confirm" />
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <FormattedMessage
          id="apps.invalidateClientSecret.confirmation"
          defaultMessage="Are you sure you want to proceed with Client Secret invalidation?"
        />
      </Modal.Body>
      <Modal.Footer>
        <Button
          className="btn btn-primary"
          disabled={isDisabled}
          type="button"
          onClick={onCancel}
        >
          <FormattedMessage id="button.cancel" defaultMessage="Cancel" />
        </Button>
        <Button
          className="btn btn-danger"
          disabled={isDisabled}
          type="button"
          onClick={onConfirm}
        >
          <FormattedMessage
            id="apps.invalidateClientSecret"
            defaultMessage="Invalidate Client Secret"
          />
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

ExpireClientSecretConfirmation.propTypes = {
  isShown: PropTypes.bool,
  isDisabled: PropTypes.bool,
  onConfirm: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
};

export default ExpireClientSecretConfirmation;
