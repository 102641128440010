import React from 'react';
import { FormattedMessage } from 'react-intl';

import {
  getActiveTabFromQueryString,
  isProPage,
  selectTab as selectTabViaURL,
} from '../../utils';
import PageContentWrapper from '../PageContentWrapper';
import useSelectedTab from '../hooks/useSelectedTab';
import ChangelogTab from './components/ChangelogTab';

import PropTypes from 'prop-types';
import 'react-tabs/style/react-tabs.css';
import './updates.scss';

export const UPDATES_TABS = {
  CHANGELOG: 'changelog',
};

const Updates = ({ location }) => {
  const selectedTab = getActiveTabFromQueryString(location);

  const { setIsLoadingTab, isLoadingTab } = useSelectedTab(
    selectedTab,
    UPDATES_TABS.CHANGELOG,
    UPDATES_TABS,
  );

  const selectTab = index => {
    setIsLoadingTab(true);
    selectTabViaURL(index);
  };

  const getCurrentTab = () => {
    switch (selectedTab) {
      case UPDATES_TABS.CHANGELOG:
        return <ChangelogTab isProPage={isProPage(location?.pathname ?? '')} />;

      default:
        return null;
    }
  };

  return (
    <div className="updates">
      <div className="row">
        <div className="tabordion" tabIndex="-1">
          <section id="updates-section" tabIndex="0">
            <div
              onClick={() => selectTab(UPDATES_TABS.CHANGELOG)}
              role="tab"
              tabIndex="0"
              className={
                selectedTab === UPDATES_TABS.CHANGELOG
                  ? 'option-label selected'
                  : 'option-label'
              }
            >
              <FormattedMessage
                id={'label.changelog'}
                defaultMessage="Changelog"
              />
            </div>
          </section>
          <div className="tab-content" tabIndex="0">
            <PageContentWrapper
              location={location}
              availableTabs={UPDATES_TABS}
              isLoadingTab={isLoadingTab}
            >
              {getCurrentTab()}
            </PageContentWrapper>
          </div>
        </div>
      </div>
    </div>
  );
};

Updates.propTypes = {
  location: PropTypes.object,
};

export default Updates;
