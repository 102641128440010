export const apiClientType = {
  None: 'None',
  MyUplink: 'MyUplink',
  MyUplinkPro: 'MyUplinkPro',
};

export const FOR_DEVELOPER_PAGE_TABS = {
  PUBLIC_APPLICATIONS_LIST: 0,
  PRO_APPLICATIONS_LIST: 1,
  CREATE_NEW_APP: 2,
  CREATE_NEW_PRO_APP: 3,
};

export const apiClientTypeToTabMapper = {
  MyUplink: FOR_DEVELOPER_PAGE_TABS.PUBLIC_APPLICATIONS_LIST,
  MyUplinkPro: FOR_DEVELOPER_PAGE_TABS.PRO_APPLICATIONS_LIST,
};

export const BRAND_APPROVAL_STATUS = {
  Approved: 'Approved',
  Pending: 'Pending',
  Rejected: 'Rejected',
};

export const ROUTES = {
  ROOT: '/',
  DOCUMENTATION: {
    ROOT: '/documentation',
    INTRO: '/documentation/intro',
    AUTH: '/documentation/auth',
    PARAMETERS: '/documentation/parameters',
    UPDATES: '/documentation/updates',
    SWAGGER: '/documentation/swagger',
  },
  PRO_DOCUMENTATION: {
    ROOT: '/pro-documentation',
    INTRO: '/pro-documentation/intro',
    AUTH: '/pro-documentation/auth',
    PARAMETERS: '/pro-documentation/parameters',
    UPDATES: '/pro-documentation/updates',
    SWAGGER: '/pro-documentation/swagger',
  },
  PRIVACY_POLICY: '/privacy-policy',
  TERMS_OF_SERVICE: '/terms-of-service',
  APPLICATIONS: '/apps',
  PROFILE_SETTINGS: '/profile',
  LOGOUT: '/logout',
  LOGIN: '/login',
  FORGOT_PASSWORD: '/forgot-password',
  API_SERVICES_AGREEMENT: '/api-services-agreement',
};
