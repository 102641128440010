import React, { Component } from 'react';
import { connect } from 'react-redux';
import { IntlProvider, addLocaleData } from 'react-intl';
import PropTypes from 'prop-types';

import messages_enUs from './supported-languages/en-US.json';

import locale_en from 'react-intl/locale-data/en';
import locale_de from 'react-intl/locale-data/de';
import locale_sv from 'react-intl/locale-data/sv';

addLocaleData([...locale_en, ...locale_de, ...locale_sv]);

const cultures = {
  cs: {
    language: null,
    dialects: ['cs', 'cs-CZ'],
    messages: null,
  },
  de: {
    language: 'de-DE',
    dialects: ['de', 'de-AT', 'de-DE', 'de-LI', 'de-LI', 'de-CH'],
    messages: null,
  },
  et: {
    language: null,
    dialects: ['et', 'et-EE'],
    messages: null,
  },
  en: {
    language: 'en-US',
    dialects: [
      'en',
      'en-AU',
      'en-BZ',
      'en-CA',
      'en-CB',
      'en-IE',
      'en-JM',
      'en-NZ',
      'en-PH',
      'en-ZA',
      'en-TT',
      'en-GB',
      'en-US',
      'en-ZW',
    ],
    messages: messages_enUs,
  },
  fi: {
    language: null,
    dialects: ['fi', 'fi-FI'],
    messages: null,
  },
  fr: {
    language: null,
    dialects: ['fr', 'fr-BE', 'fr-CA', 'fr-FR', 'fr-LU', 'fr-MC', 'fr-CH'],
    messages: null,
  },
  hu: {
    language: null,
    dialects: ['hu', 'hu-HU'],
    messages: null,
  },
  nl: {
    language: null,
    dialects: ['nl', 'nl-BE', 'nl-NL'],
    messages: null,
  },
  nb: {
    language: null,
    dialects: ['no', 'nb-NO'],
    messages: null,
  },
  pl: {
    language: null,
    dialects: ['pl', 'pl-PL'],
    messages: null,
  },
  ru: {
    language: null,
    dialects: ['ru', 'ru-RU'],
    messages: null,
  },
  sv: {
    language: 'sv-SE',
    dialects: ['sv', 'sv-SE', 'sv-FI'],
    messages: null,
  },
};

class Wrapper extends Component {
  render() {
    const culture = this.determineCulture(
      cultures,
      this.props.selectedLanguage,
    );

    return (
      <IntlProvider
        locale={culture.language}
        key={culture.language}
        messages={culture.messages}
      >
        {React.Children.only(this.props.children)}
      </IntlProvider>
    );
  }

  determineCulture(cultures, requestedLanguage) {
    const cultureKey = Object.keys(cultures).find(key =>
      cultures[key].dialects.includes(requestedLanguage),
    );

    return cultures[cultureKey] || cultures.en;
  }
}

Wrapper.propTypes = {
  selectedLanguage: PropTypes.string,
  children: PropTypes.any,
};

const mapStateToProps = state => {
  return {
    selectedLanguage: state.language.selectedLanguage,
  };
};

export default connect(mapStateToProps, undefined)(Wrapper);
