import { API, API_URL, CONFIG_JSON } from './client';

export const getUserInfo = () => {
  return API.get(`${API_URL}/v2/users/me`);
};

export const getUserActiveServicePartner = () => {
  return API.get(`${API_URL}/v2/service-partners/me/active`);
};

export const sendRecoveryLink = email => {
  const body = { email };
  return API.post(`${API_URL}/v2/users/recovery-link/send`, body, CONFIG_JSON);
};

export const verifyRecoveryToken = token => {
  const body = { token };
  return API.post(
    `${API_URL}/v2/users/recovery-link/verify`,
    body,
    CONFIG_JSON,
  );
};

export const changePassword = (id, oldPassword, newPassword) => {
  const body = {
    oldPassword: oldPassword,
    newPassword: newPassword,
  };

  return API.post(`${API_URL}/v2/users/${id}/password`, body, CONFIG_JSON);
};

export const resetPassword = (token, password) => {
  const body = { token, password };
  return API.post(
    `${API_URL}/v2/users/recovery-link/reset-password`,
    body,
    CONFIG_JSON,
  );
};

export const getUserProfile = id => {
  return API.get(`${API_URL}/v2/users/${id}/profile`);
};

export const getTimeZones = () => {
  return API.get(`${API_URL}/v2/timezones`);
};

export const updateUserProfile = (id, profile) => {
  return API.put(`${API_URL}/v2/users/${id}/profile`, profile, CONFIG_JSON);
};

export const getAddressInfo = addressId => {
  return API.get(`${API_URL}/v2/addresses/${addressId}`);
};

export const addAddressIdToUser = (userId, addressId) => {
  const body = {
    addressId: addressId,
  };

  return API.patch(`${API_URL}/v2/users/${userId}`, body, CONFIG_JSON);
};

export const changeAddressInfo = address => {
  return API.put(
    `${API_URL}/v2/addresses/user/${address.id}`,
    address,
    CONFIG_JSON,
  );
};

export const createAddressInfo = address => {
  return API.post(`${API_URL}/v2/addresses/user`, address, CONFIG_JSON);
};

export const changeEmail = (id, oldEmail, newEmail, password) => {
  const body = {
    userId: id,
    oldEmail: oldEmail,
    newEmail: newEmail,
    password: password,
  };

  return API.post(`${API_URL}/v2/users/${id}/email`, body, CONFIG_JSON);
};

export const sendConfirmationDeleteUser = () => {
  return API.post(`${API_URL}/v2/users/send-confirmation-delete-user`);
};

export const deleteUserAccount = async (userId, password) => {
  return await API.delete(`${API_URL}/v2/users/${userId}`, {
    data: { password },
  });
};

export const validateUserCredentials = (username, password) => {
  const body = {
    grant_type: 'password',
    email: username,
    password: password,
  };
  return API.post(`${API_URL}/v2/users/validate`, body, CONFIG_JSON);
};

export const getCountriesList = () => API.get(`${API_URL}/v2/countries`);
