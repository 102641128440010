export const LOAD_APPS = 'forDeveloper/LOAD_APPS';
export const LOAD_APPS_SUCCESS = 'forDeveloper/LOAD_APPS_SUCCESS';
export const LOAD_APPS_FAIL = 'forDeveloper/LOAD_APPS_FAIL';

export const ADD_APP = 'forDeveloper/ADD_APP';
export const ADD_APP_SUCCESS = 'forDeveloper/ADD_APP_SUCCESS';
export const ADD_APP_FAIL = 'forDeveloper/ADD_APP_FAIL';

export const DELETE_APP = 'forDeveloper/DELETE_APP';
export const DELETE_APP_SUCCESS = 'forDeveloper/DELETE_APP_SUCCESS';
export const DELETE_APP_FAIL = 'forDeveloper/DELETE_APP_FAIL';

export const UPDATE_APP = 'forDeveloper/UPDATE_APP';
export const UPDATE_APP_SUCCESS = 'forDeveloper/UPDATE_APP_SUCCESS';
export const UPDATE_APP_FAIL = 'forDeveloper/UPDATE_APP_FAIL';

export const ADD_SECRET = 'forDeveloper/ADD_SECRET';
export const ADD_SECRET_SUCCESS = 'forDeveloper/ADD_SECRET_SUCCESS';
export const ADD_SECRET_FAIL = 'forDeveloper/ADD_SECRET_FAIL';

export const EXPIRE_SECRET = 'forDeveloper/EXPIRE_SECRET';
export const EXPIRE_SECRET_SUCCESS = 'forDeveloper/EXPIRE_SECRET_SUCCESS';
export const EXPIRE_SECRET_FAIL = 'forDeveloper/EXPIRE_SECRET_FAIL';

const initialState = {
  loading: false,
  error: null,
  apps: [],
};

const actionHandlers = {
  [LOAD_APPS](state) {
    return { ...state, loading: true };
  },
  [LOAD_APPS_SUCCESS](state, action) {
    return { ...state, loading: false, apps: action.payload.data };
  },
  [LOAD_APPS_FAIL](state, action) {
    return { ...state, loading: false, error: action.error };
  },

  [ADD_APP](state) {
    return { ...state, loading: true };
  },
  [ADD_APP_SUCCESS](state, action) {
    return { ...state, loading: false, apps: action.payload.data };
  },
  [ADD_APP_FAIL](state, action) {
    return { ...state, loading: false, error: action.error };
  },

  [DELETE_APP](state) {
    return { ...state, loading: true };
  },
  [DELETE_APP_SUCCESS](state) {
    return { ...state, loading: false };
  },
  [DELETE_APP_FAIL](state, action) {
    return { ...state, loading: false, error: action.error };
  },

  [UPDATE_APP](state) {
    return { ...state, loading: true };
  },
  [UPDATE_APP_SUCCESS](state) {
    return { ...state, loading: false };
  },
  [UPDATE_APP_FAIL](state, action) {
    return { ...state, loading: false, error: action.error };
  },

  [ADD_SECRET](state) {
    return { ...state, loading: true };
  },
  [ADD_SECRET_SUCCESS](state, { payload: { data } }) {
    return {
      ...state,
      loading: false,
      apps: state.apps.map(app => (app.id === data.id ? data : app)),
    };
  },
  [ADD_SECRET_FAIL](state, action) {
    return { ...state, loading: false, error: action.error };
  },

  [EXPIRE_SECRET](state) {
    return { ...state, loading: true };
  },
  [EXPIRE_SECRET_SUCCESS](state, { meta }) {
    const { clientId, secretId } = meta?.previousAction?.payload?.request || {};
    return {
      ...state,
      loading: false,
      apps: state.apps.map(app =>
        app.id === clientId
          ? { ...app, secrets: app.secrets.filter(s => s.id !== secretId) }
          : app,
      ),
    };
  },
  [EXPIRE_SECRET_FAIL](state, action) {
    return { ...state, loading: false, error: action.error };
  },
};

export default function reducer(state = initialState, action) {
  const { type } = action;
  const actionHandler = actionHandlers[type];
  if (actionHandler) {
    return actionHandler(state, action);
  }

  return state;
}

export function getApps() {
  return {
    types: [LOAD_APPS, LOAD_APPS_SUCCESS, LOAD_APPS_FAIL],
    payload: {
      request: {
        url: '/v2/clients/user',
      },
    },
  };
}

export function addApp({ appName, apiClientType, description, callbackUrl }) {
  return {
    types: [ADD_APP, ADD_APP_SUCCESS, ADD_APP_FAIL],
    payload: {
      request: {
        method: 'post',
        url: '/v2/clients',
        data: {
          name: appName,
          apiClientType,
          description,
          redirectUrl: callbackUrl,
        },
      },
    },
  };
}

export function deleteApp(appId) {
  return {
    types: [DELETE_APP, DELETE_APP_SUCCESS, DELETE_APP_FAIL],
    payload: {
      request: {
        method: 'delete',
        url: `/v2/clients/${appId}`,
      },
    },
  };
}

export function updateApp(appId, appName, description, callbackUrl) {
  return {
    types: [UPDATE_APP, UPDATE_APP_SUCCESS, UPDATE_APP_FAIL],
    payload: {
      request: {
        method: 'put',
        url: `/v2/clients/${appId}`,
        data: {
          name: appName,
          description,
          redirectUrl: callbackUrl,
        },
      },
    },
  };
}

export function addSecret(clientId) {
  return {
    types: [ADD_SECRET, ADD_SECRET_SUCCESS, ADD_SECRET_FAIL],
    payload: {
      request: {
        method: 'post',
        url: `/v2/clients/${clientId}/new-secret`,
      },
    },
  };
}

export function expireSecret(clientId, secretId) {
  return {
    types: [EXPIRE_SECRET, EXPIRE_SECRET_SUCCESS, EXPIRE_SECRET_FAIL],
    payload: {
      request: {
        clientId,
        secretId,
        method: 'patch',
        url: `/v2/clients/${clientId}/expire-secret/${secretId}`,
      },
    },
  };
}
