import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';

import Spinner from '../../components/Spinner';
import DeleteAppModal from './components/DeleteAppModal';

import Fade from '../../components/Fade';
import AppList from './components/AppList';
import CreateNewApp from './components/CreateNewAppForm';
import LegalInfoModal from './components/LegalInfoModal/LegalInfoModal';

import {
  addSecret,
  deleteApp,
  expireSecret,
  getApps,
  updateApp,
} from './reducers';

import 'react-tabs/style/react-tabs.css';
import './apps.scss';

import {
  getActiveTabFromQueryString,
  selectTab as selectTabViaURL,
} from '../../utils';

import useSelectedTab from '../hooks/useSelectedTab';
// import PageContentWrapper from "../PageContentWrapper";

import { apiClientType, FOR_DEVELOPER_PAGE_TABS } from '../../constants';

const ForDeveloperPage = ({
  apps,
  addClientSecret,
  expireClientSecret,
  hasActiveSP,
  loadClients,
  deleteClient,
  updateClient,
  location,
}) => {
  const selectedTabString = getActiveTabFromQueryString(location);
  const selectedTab = isNaN(Number(selectedTabString))
    ? selectedTabString
    : Number(selectedTabString);

  const { setIsLoadingTab } = useSelectedTab(
    selectedTab,
    FOR_DEVELOPER_PAGE_TABS.PUBLIC_APPLICATIONS_LIST,
    FOR_DEVELOPER_PAGE_TABS,
  );

  const selectTab = index => {
    setIsLoadingTab(true);
    selectTabViaURL(index);
  };

  const [state, setState] = useState({
    loading: false,
    showLegalInfoModal: false,
    deleteApp: {},
    showDeleteAppModal: false,
    isValueSaved: false,
  });

  const mounted = useRef(false);

  useEffect(() => {
    mounted.current = true;

    return () => {
      mounted.current = false;
    };
  }, []);

  const initClients = async () => {
    setState({ ...state, loading: true });
    await loadClients();
    setState({ ...state, loading: false });
  };

  useEffect(() => {
    initClients();
  }, []);

  const _toggleLegalModal = () => {
    setState({ ...state, showLegalInfoModal: !state.showLegalInfoModal });
  };

  const _toggleDeleteModal = app => {
    setState({
      ...state,
      showDeleteAppModal: !state.showDeleteAppModal,
      deleteApp: app,
    });
  };

  const _onAppAdded = (
    newActiveTab = FOR_DEVELOPER_PAGE_TABS.PUBLIC_APPLICATIONS_LIST,
  ) => {
    selectTab(newActiveTab);
  };

  const _goToCreateApp = (
    createAppTab = FOR_DEVELOPER_PAGE_TABS.CREATE_NEW_APP,
  ) => {
    selectTab(createAppTab);
  };

  const _deleteApp = async appId => {
    setState({ ...state, loading: true });

    await deleteClient(appId);
    await loadClients();

    setState({ ...state, loading: false });
    _toggleDeleteModal({});
  };

  const _updateApp = async (id, appName, appDescription, appCallbackUrl) => {
    setState({ ...state, loading: true });

    await updateClient(id, appName, appDescription, appCallbackUrl);
    await loadClients();

    setState({ ...state, loading: false, isValueSaved: true });

    setTimeout(() => {
      if (mounted.current) {
        setState({ ...state, isValueSaved: false });
      }
    }, 1000);
  };

  const _getSortedApps = apps => {
    if (!apps) return [];

    return [].slice.call(apps).sort(function (a, b) {
      if (a.name < b.name) {
        return -1;
      }
      if (a.name > b.name) {
        return 1;
      }
      return 0;
    });
  };

  const onDeleteModalHide = () =>
    setState({ ...state, showDeleteAppModal: !state.showDeleteAppModal });

  const { loading, deleteApp, showDeleteAppModal, showLegalInfoModal } = state;

  const isCreateNewAppTab =
    selectedTab === FOR_DEVELOPER_PAGE_TABS.CREATE_NEW_APP;
  const isCreateNewProAppTab =
    selectedTab === FOR_DEVELOPER_PAGE_TABS.CREATE_NEW_PRO_APP && hasActiveSP;

  return (
    <div className="fordeveloper">
      <div className="fordeveloper__wrapper row"></div>
      {/* <PageContentWrapper location={location} isLoadingTab={isLoadingTab}> */}
      <div className="row">
        <div className="">
          <Tabs
            className="tabordion"
            defaultIndex={FOR_DEVELOPER_PAGE_TABS.PUBLIC_APPLICATIONS_LIST}
            selectedIndex={0}
          >
            <TabList>
              <Tab
                onClick={() =>
                  selectTab(FOR_DEVELOPER_PAGE_TABS.PUBLIC_APPLICATIONS_LIST)
                }
                eventKey={FOR_DEVELOPER_PAGE_TABS.PUBLIC_APPLICATIONS_LIST}
                className={`option-label ${!isCreateNewAppTab ? 'selected' : ''}`}
              >
                Applications List
              </Tab>

              <Tab
                onClick={() => _goToCreateApp()}
                eventKey={FOR_DEVELOPER_PAGE_TABS.PUBLIC_APPLICATIONS_LIST}
                className={`option-label ${isCreateNewAppTab ? 'selected' : ''}`}
              >
                Create New Application
              </Tab>
            </TabList>

            <TabPanel id="tab1">
              {loading && (
                <Fade>
                  <Spinner />
                </Fade>
              )}

              {isCreateNewAppTab || isCreateNewProAppTab ? (
                <CreateNewApp
                  apiClientType={
                    isCreateNewAppTab
                      ? apiClientType.MyUplink
                      : apiClientType.MyUplinkPro
                  }
                  onAppAdded={_onAppAdded}
                  toggleLegalModal={_toggleLegalModal}
                />
              ) : (
                <AppList
                  apps={_getSortedApps(apps)}
                  goToCreateApp={_goToCreateApp}
                  toggleDeleteModal={_toggleDeleteModal}
                  updateClient={_updateApp}
                  isValueSaved={state.isValueSaved}
                  addClientSecret={addClientSecret}
                  expireClientSecret={expireClientSecret}
                  selectedTab={selectedTab}
                  hasActiveSP={hasActiveSP}
                />
              )}
            </TabPanel>
          </Tabs>
        </div>
      </div>
      {/* </PageContentWrapper> */}
      {showLegalInfoModal && (
        <LegalInfoModal
          isShow={state.showLegalInfoModal}
          onHide={() =>
            setState({ ...state, showLegalInfoModal: !showLegalInfoModal })
          }
        />
      )}
      {showDeleteAppModal && (
        <DeleteAppModal
          id={deleteApp.id}
          name={deleteApp.name}
          isShow={showDeleteAppModal}
          deleteApp={_deleteApp}
          onHide={onDeleteModalHide}
        />
      )}
    </div>
  );
};

ForDeveloperPage.propTypes = {
  userInfo: PropTypes.object,
  hasActiveSP: PropTypes.bool.isRequired,
  loadClients: PropTypes.func.isRequired,
  deleteClient: PropTypes.func.isRequired,
  updateClient: PropTypes.func.isRequired,
  addClientSecret: PropTypes.func.isRequired,
  expireClientSecret: PropTypes.func.isRequired,
  apps: PropTypes.arrayOf(
    PropTypes.shape({
      sort: PropTypes.func.isRequired,
    }),
  ).isRequired,
};

export default connect(
  ({ forDeveloper, app }) => ({
    apps: forDeveloper.apps,
    userInfo: app.userInfo,
    hasActiveSP: app.hasActiveSP,
  }),
  {
    loadClients: getApps,
    deleteClient: deleteApp,
    updateClient: updateApp,
    addClientSecret: addSecret,
    expireClientSecret: expireSecret,
  },
)(ForDeveloperPage);
