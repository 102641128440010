import React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';

import GenericDocument from './components/GenericDocument';
import QuickStartGuide from './components/QuickStartGuide';
import {
  getActiveTabFromQueryString,
  getDocumentNameIdPrefix,
  isProPage,
  selectTab as selectTabViaURL,
} from '../../utils';
import PageContentWrapper from '../PageContentWrapper';

import './intro.scss';
import useSelectedTab from '../hooks/useSelectedTab';

export const INTRO_TABS = {
  INTRO: 'intro',
  QUICK_START_GUIDE: 'quick-start-guide',
  DATA_FORMAT: 'data-format',
  PARAMETERS: 'parameters',
  ERROR_MESSAGES: 'error-messages',
  HTTP_REDIRECTS: 'http-redirects',
  PAGINATION: 'pagination',
  RATE_LIMITING_CLIENT_ABUSE: 'rate-limiting-client-abuse',
  LANGUAGE: 'language',
  VERSIONING: 'versioning',
};

const Intro = ({ location }) => {
  const selectedTab = getActiveTabFromQueryString(location);

  const { isLoadingTab, setIsLoadingTab } = useSelectedTab(
    selectedTab,
    INTRO_TABS.INTRO,
    INTRO_TABS,
  );

  const selectTab = index => {
    setIsLoadingTab(true);
    selectTabViaURL(index);
  };

  const getCurrentTab = () => {
    const prefix = getDocumentNameIdPrefix(location?.pathname ?? '');
    switch (selectedTab) {
      case INTRO_TABS.INTRO:
        return <GenericDocument id={`${prefix}wiki.${INTRO_TABS.INTRO}`} />;
      case INTRO_TABS.QUICK_START_GUIDE:
        return <QuickStartGuide isPro={isProPage(location?.pathname ?? '')} />;
      // return <GenericDocument id={`${prefix}wiki.quick-start-guide`} />;
      case INTRO_TABS.DATA_FORMAT:
        return (
          <GenericDocument id={`${prefix}wiki.${INTRO_TABS.DATA_FORMAT}`} />
        );
      case INTRO_TABS.PARAMETERS:
        return (
          <GenericDocument
            id={`${prefix}wiki.intro-${INTRO_TABS.PARAMETERS}`}
          />
        );
      case INTRO_TABS.ERROR_MESSAGES:
        return (
          <GenericDocument id={`${prefix}wiki.${INTRO_TABS.ERROR_MESSAGES}`} />
        );
      case INTRO_TABS.HTTP_REDIRECTS:
        return (
          <GenericDocument id={`${prefix}wiki.${INTRO_TABS.HTTP_REDIRECTS}`} />
        );
      case INTRO_TABS.PAGINATION:
        return (
          <GenericDocument id={`${prefix}wiki.${INTRO_TABS.PAGINATION}`} />
        );
      case INTRO_TABS.RATE_LIMITING_CLIENT_ABUSE:
        return (
          <GenericDocument
            id={`${prefix}wiki.${INTRO_TABS.RATE_LIMITING_CLIENT_ABUSE}`}
          />
        );
      case INTRO_TABS.LANGUAGE:
        return <GenericDocument id={`${prefix}wiki.${INTRO_TABS.LANGUAGE}`} />;
      case INTRO_TABS.VERSIONING:
        return (
          <GenericDocument id={`${prefix}wiki.${INTRO_TABS.VERSIONING}`} />
        );
      default:
        return null;
    }
  };

  return (
    <div className="intro">
      <div className="row">
        <div className="tabordion" tabIndex="-1">
          <section id="intro-section" tabIndex="0">
            <div
              onClick={() => selectTab(INTRO_TABS.INTRO)}
              tabIndex="0"
              className={
                selectedTab === INTRO_TABS.INTRO
                  ? 'option-label selected'
                  : 'option-label'
              }
            >
              <FormattedMessage
                id={'label.intro'}
                defaultMessage="Introduction"
              />
            </div>
            <div
              onClick={() => selectTab(INTRO_TABS.QUICK_START_GUIDE)}
              tabIndex="0"
              className={
                selectedTab === INTRO_TABS.QUICK_START_GUIDE
                  ? 'option-label selected'
                  : 'option-label'
              }
            >
              <FormattedMessage
                id={'label.quick-start-guide'}
                defaultMessage="Quick Start Guide"
              />
            </div>
            <div
              onClick={() => selectTab(INTRO_TABS.DATA_FORMAT)}
              tabIndex="0"
              className={
                selectedTab === INTRO_TABS.DATA_FORMAT
                  ? 'option-label selected'
                  : 'option-label'
              }
            >
              <FormattedMessage
                id={'label.data-format'}
                defaultMessage="Data Format"
              />
            </div>
            <div
              onClick={() => selectTab(INTRO_TABS.PARAMETERS)}
              tabIndex="0"
              className={
                selectedTab === INTRO_TABS.PARAMETERS
                  ? 'option-label selected'
                  : 'option-label'
              }
            >
              <FormattedMessage
                id={'label.parameters'}
                defaultMessage="Parameters"
              />
            </div>
            {
              <div
                onClick={() => selectTab(INTRO_TABS.ERROR_MESSAGES)}
                tabIndex="0"
                className={
                  selectedTab === INTRO_TABS.ERROR_MESSAGES
                    ? 'option-label selected'
                    : 'option-label'
                }
              >
                <FormattedMessage
                  id={'label.error-messages'}
                  defaultMessage="Error Messages"
                />
              </div>
            }
            <div
              onClick={() => selectTab(INTRO_TABS.HTTP_REDIRECTS)}
              tabIndex="0"
              className={
                selectedTab === INTRO_TABS.HTTP_REDIRECTS
                  ? 'option-label selected'
                  : 'option-label'
              }
            >
              <FormattedMessage
                id={'label.http-redirects'}
                defaultMessage="HTTP Redirects"
              />
            </div>
            <div
              onClick={() => selectTab(INTRO_TABS.PAGINATION)}
              tabIndex="0"
              className={
                selectedTab === INTRO_TABS.PAGINATION
                  ? 'option-label selected'
                  : 'option-label'
              }
            >
              <FormattedMessage
                id={'label.pagination'}
                defaultMessage="Pagination"
              />
            </div>
            <div
              onClick={() => selectTab(INTRO_TABS.RATE_LIMITING_CLIENT_ABUSE)}
              tabIndex="0"
              className={
                selectedTab === INTRO_TABS.RATE_LIMITING_CLIENT_ABUSE
                  ? 'option-label selected'
                  : 'option-label'
              }
            >
              <FormattedMessage
                id={'label.rate-limiting-client-abuse'}
                defaultMessage="Rate Limiting and client abuse"
              />
            </div>
            <div
              onClick={() => selectTab(INTRO_TABS.LANGUAGE)}
              tabIndex="0"
              className={
                selectedTab === INTRO_TABS.LANGUAGE
                  ? 'option-label selected'
                  : 'option-label'
              }
            >
              <FormattedMessage
                id={'label.language'}
                defaultMessage="Language"
              />
            </div>
            <div
              onClick={() => selectTab(INTRO_TABS.VERSIONING)}
              tabIndex="0"
              className={
                selectedTab === INTRO_TABS.VERSIONING
                  ? 'option-label selected'
                  : 'option-label'
              }
            >
              <FormattedMessage
                id={'label.versioning'}
                defaultMessage="Versioning"
              />
            </div>
          </section>
          <div className="tab-content" tabIndex="0">
            <PageContentWrapper
              location={location}
              availableTabs={INTRO_TABS}
              isLoadingTab={isLoadingTab}
            >
              {getCurrentTab()}
            </PageContentWrapper>
          </div>
        </div>
      </div>
    </div>
  );
};

const InjectedIntro = injectIntl(Intro);

export default InjectedIntro;
