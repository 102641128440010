import React from 'react';
import { Route } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { injectIntl } from 'react-intl';

import Slide from './components/Slideshow/components/Slide';
import Content from '../SignOutLayout/components/Content';
import Header from '../SignOutLayout/components/Header';
import Footer from '../Footer/Footer';
import { Logo } from '../Logo';

import './signedoutlayout.scss';

const SignOutLayout = ({ component: Component, url, status, ...rest }) => {
  return (
    <div className="signed-out-layout-wrapper">
      {status &&
        status.statusCode &&
        status.statusCode.value !== 'status-0' && (
          <div className="status-banner">{status.statusText}</div>
        )}
      <div className="welcome-page">
        <div className="spacer" />
        {rest.path === '/login' || rest.path === '/forgot-password' ? (
          <>
            <Header url={url} />
            <div className="page-centered welcome-content">
              <Content
                id="slider"
                tagName="section"
                className="wide fullPage"
                isUpAlignment={true}
                aria-label="App features slides"
              >
                <Slide />
              </Content>
              <Content
                tagName="section"
                className="narrow fullPage login-section-accessible-background"
                isUpAlignment={true}
              >
                <Route
                  {...rest}
                  render={matchProps => <Component {...matchProps} />}
                />
              </Content>
            </div>
          </>
        ) : (
          <div className="aboutApp-page">
            <div className="aboutApp-page-header">
              <Logo />
            </div>
            <div className="aboutApp-page-content">
              <Content tagName="section" isUpAlignment={true}>
                <Route
                  {...rest}
                  render={matchProps => <Component {...matchProps} />}
                />
              </Content>
            </div>
          </div>
        )}
        <Footer />
      </div>
    </div>
  );
};

SignOutLayout.propTypes = {
  intl: PropTypes.object.isRequired,
  url: PropTypes.object,
};

const mapStateToProps = state => {
  return {
    url: state.app.url,
    status: state.app.status,
  };
};

export default connect(mapStateToProps, null)(injectIntl(SignOutLayout));
