// @ts-check
import axios from 'axios';

import { getConfig } from './config';

export const API_URL = getConfig().REACT_APP_API_URL || '';
export const WEB_URL = getConfig().REACT_APP_WEB_URL || '';
export const WEB_PRO_URL = getConfig().REACT_APP_WEB_PRO_URL || '';
export const WEB_DEV_URL = getConfig().REACT_APP_WEB_DEV_URL || '';
export const WEB_REGISTER_URL = getConfig().REACT_APP_WEB_REGISTER_URL || '';
export const ENV = getConfig().REACT_APP_ENV || '';
export const PUBLIC_API_URL = getConfig().REACT_APP_PUBLIC_API_URL || '';
export const PUBLIC_PRO_API_URL =
  getConfig().REACT_APP_PUBLIC_PRO_API_URL || '';
export const READABLE_WEB_URL = getConfig().REACT_APP_READABLE_WEB_URL || '';
export const CLIENT_ID = getConfig().REACT_APP_CLIENT_ID || '';

export const CONFIG_JSON = {
  headers: {
    'Content-Type': 'application/json',
  },
};

export const CONFIG_FORM = {
  headers: {
    'Content-Type': 'application/x-www-form-urlencoded',
    Accept: 'application/json',
  },
};

export const API = axios.create({
  baseURL: API_URL,
  headers: {
    ...CONFIG_JSON.headers,
  },
});
