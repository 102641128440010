import React from 'react';
import { FormattedMessage } from 'react-intl';
import PropTypes from 'prop-types';

import { CopyToClipboard } from 'react-copy-to-clipboard';
import copyImage from './assets/save.svg';
import eyeImage from './assets/eye.svg';

import './input-with-copy.scss';

class InputWithCopy extends React.Component {
  state = {
    focused: false,
    error: null,
    hidden: false,
  };

  static propTypes = {
    title: PropTypes.string,
    id: PropTypes.string,
    values: PropTypes.object,
    name: PropTypes.string,
    type: PropTypes.string,
    text: PropTypes.string,
    validator: PropTypes.func,
    label: PropTypes.string,
    error: PropTypes.string,
    hide: PropTypes.bool,
    children: PropTypes.oneOfType([
      PropTypes.element,
      PropTypes.arrayOf(PropTypes.element),
    ]),
  };

  componentDidMount() {
    this.setState({ hidden: !!this.props.hide });
  }

  toggleShow = () => this.setState({ hidden: !this.state.hidden });
  render() {
    const { focused, hidden } = this.state;
    const { id, values, name, text, label, hide } = this.props;

    return (
      <div className="InputWithCopy">
        <FormattedMessage id={id} values={values}>
          {placeholder => (
            <div className={'inputWithCopyComponent'}>
              {label ? (
                <label className="inputWithCopyComponent__label" htmlFor={id}>
                  {label}
                </label>
              ) : (
                ''
              )}

              <label
                htmlFor={this.props.id}
                className="inputWithCopyComponent__placeholder__label"
              >
                <span
                  className={
                    'inputWithCopyComponent__placeholder' +
                    ((text && text.length) || focused ? ' focused' : '')
                  }
                >
                  {placeholder}
                </span>
                <input
                  className={'inputWithCopyComponent__field'}
                  id={id}
                  name={name}
                  value={text}
                  type={hidden ? 'password' : 'text'}
                  autoComplete="new-password"
                  readOnly
                />
              </label>
              {this.props.children}
            </div>
          )}
        </FormattedMessage>
        {document.queryCommandSupported('copy') && (
          <CopyToClipboard text={text}>
            <button className="copyBtn" type="button">
              <img src={copyImage} alt="copyImage" />
            </button>
          </CopyToClipboard>
        )}
        {hide && (
          <button className="hideBtn" type="button" onClick={this.toggleShow}>
            <img src={eyeImage} alt="eyeImage" />
          </button>
        )}
      </div>
    );
  }
}

export default InputWithCopy;
