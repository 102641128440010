import * as yup from 'yup';

export const schema = yup.object().shape({
  username: yup
    .string()
    .trim()
    .email('Username should be email address')
    .required('Username is mandatory field'),
  password: yup.string().trim().required('Password is mandatory field'),
});

export const requestErrors = {
  'Supplied password is not correct!': 'Invalid username or password',
  'There is no user with such email!': 'Invalid username or password',
  unknown: 'An error has occurred. Try again later.',
  'invalid request': 'Invalid username or password',
};
