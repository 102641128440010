import PropTypes from 'prop-types';
import React from 'react';
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';

import AppListItem from './AppListItem';

import { apiClientType, FOR_DEVELOPER_PAGE_TABS } from '../../../constants';
import { selectTab } from '../../../utils';

import 'react-tabs/style/react-tabs.css';

const CreateAppLink = ({ onClick }) => (
  <p className="apps-heading">
    Click{' '}
    <a onClick={onClick} href="#">
      here
    </a>{' '}
    to create a new one.
  </p>
);

const AppList = ({
  apps,
  goToCreateApp,
  toggleDeleteModal,
  updateClient,
  isValueSaved,
  addClientSecret,
  expireClientSecret,
  selectedTab,
  hasActiveSP,
}) => {
  const publicApps = apps.filter(
    app => app.apiClientType === apiClientType.MyUplink,
  );
  const proApps = apps.filter(
    app => app.apiClientType === apiClientType.MyUplinkPro,
  );

  const currentApps =
    selectedTab === FOR_DEVELOPER_PAGE_TABS.PUBLIC_APPLICATIONS_LIST
      ? publicApps
      : proApps;
  const renderTab = (tabName, tabValue, isSelected) => (
    <Tab className={`option-label header-tab ${isSelected ? 'selected' : ''}`}>
      {tabName}
    </Tab>
  );

  const renderTabPanel = createNewAppTab => (
    <TabPanel className="apps-list">
      <h3 className="apps-heading">Applications</h3>
      {currentApps?.length > 0 ? (
        <>
          <CreateAppLink onClick={() => goToCreateApp(createNewAppTab)} />
          {currentApps.map(app => (
            <React.Fragment key={app.id}>
              <AppListItem
                app={app}
                toggleDeleteModal={toggleDeleteModal}
                updateClient={updateClient}
                isValueSaved={isValueSaved}
                addClientSecret={addClientSecret}
                expireClientSecret={expireClientSecret}
              />
              <hr />
            </React.Fragment>
          ))}
        </>
      ) : (
        <>
          <p>No applications here.</p>
          <CreateAppLink onClick={() => goToCreateApp(createNewAppTab)} />
        </>
      )}
    </TabPanel>
  );

  return (
    <Tabs selectedIndex={selectedTab} onSelect={index => selectTab(index)}>
      <TabList>
        {renderTab(
          'Public API',
          FOR_DEVELOPER_PAGE_TABS.PUBLIC_APPLICATIONS_LIST,
          selectedTab === FOR_DEVELOPER_PAGE_TABS.PUBLIC_APPLICATIONS_LIST,
        )}
        {hasActiveSP &&
          renderTab(
            'PRO Public API',
            FOR_DEVELOPER_PAGE_TABS.PRO_APPLICATIONS_LIST,
            selectedTab === FOR_DEVELOPER_PAGE_TABS.PRO_APPLICATIONS_LIST,
          )}
      </TabList>
      {renderTabPanel(FOR_DEVELOPER_PAGE_TABS.CREATE_NEW_APP, 'PUBLIC')}
      {hasActiveSP &&
        renderTabPanel(FOR_DEVELOPER_PAGE_TABS.CREATE_NEW_PRO_APP, 'PRO')}
    </Tabs>
  );
};

AppList.propTypes = {
  apps: PropTypes.arrayOf({
    appId: PropTypes.string,
  }).isRequired,
  goToCreateApp: PropTypes.func.isRequired,
  toggleDeleteModal: PropTypes.func.isRequired,
  updateClient: PropTypes.func.isRequired,
  isValueSaved: PropTypes.bool,
  addClientSecret: PropTypes.func.isRequired,
  expireClientSecret: PropTypes.func.isRequired,
  selectedTab: PropTypes.number.isRequired,
  hasActiveSP: PropTypes.bool.isRequired,
};

export default AppList;
