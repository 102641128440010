import qs from 'querystring-es3';
import { CLIENT_ID } from '../../api/client';

export const VALIDATE_CREDENTIALS = 'login/VALIDATE_CREDENTIALS';
export const VALIDATE_CREDENTIALS_SUCCESS =
  'login/VALIDATE_CREDENTIALS_SUCCESS';
export const VALIDATE_CREDENTIALS_FAIL = 'login/VALIDATE_CREDENTIALS_FAIL';
export const AUTH = 'login/AUTH';
export const AUTH_SUCCESS = 'login/AUTH_SUCCESS';
export const AUTH_FAIL = 'login/AUTH_FAIL';
export const LOGOUT_USER = 'login/LOGOUT_USER';

if (!CLIENT_ID) {
  throw new Error('CLIENT_ID not found in configuration!');
}

export function login(username, password) {
  const body = qs.stringify({
    grant_type: 'password',
    username: username,
    password: password,
    client_id: CLIENT_ID,
  });
  return {
    types: [AUTH, AUTH_SUCCESS, AUTH_FAIL],
    payload: {
      request: {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
          Accept: 'application/json',
        },
        method: 'post',
        url: '/oauth/token',
        data: body,
      },
    },
  };
}

export const reissueAuthToken = refreshToken => {
  const body = `grant_type=refresh_token&refresh_token=${refreshToken}&client_id=${CLIENT_ID}`;
  return {
    types: [AUTH, AUTH_SUCCESS, AUTH_FAIL],
    payload: {
      request: {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
          Accept: 'application/json',
        },
        method: 'post',
        url: '/oauth/token',
        data: body,
      },
    },
  };
};

export function validateUserCredentials(username, password) {
  return {
    types: [
      VALIDATE_CREDENTIALS,
      VALIDATE_CREDENTIALS_SUCCESS,
      VALIDATE_CREDENTIALS_FAIL,
    ],
    payload: {
      request: {
        method: 'post',
        url: '/v2/users/validate',
        data: {
          grant_type: 'password',
          email: username,
          password: password,
        },
      },
    },
  };
}

export function logoutUser() {
  return {
    type: LOGOUT_USER,
  };
}
