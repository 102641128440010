import * as api from '../../../../api';

export const GET_USER_PROFILE_REQUEST = 'profile/GET_USER_PROFILE_REQUEST';
export const GET_USER_PROFILE_RESPONSE_SUCCEDED =
  'profile/GET_USER_PROFILE_RESPONSE_SUCCEDED';
export const GET_USER_PROFILE_NOT_FOUND = 'profile/GET_USER_PROFILE_NOT_FOUND';
export const GET_USER_PROFILE_FAILED = 'profile/GET_USER_PROFILE_FAILED';

export const getUserProfile = userId => async dispatch => {
  dispatch({ type: GET_USER_PROFILE_REQUEST });
  try {
    const response = await api.getUserProfile(userId);
    if (response.status === 200) {
      dispatch({
        type: GET_USER_PROFILE_RESPONSE_SUCCEDED,
        userProfile: response.data,
      });
      return true;
    }
  } catch (e) {
    if (e.response && e.response.status === 404) {
      dispatch({ type: GET_USER_PROFILE_NOT_FOUND });
      return false;
    }
  }

  dispatch({ type: GET_USER_PROFILE_FAILED });
  return false;
};

export const GET_TIME_ZONES_REQUEST = 'profile/GET_TIME_ZONES_REQUEST';
export const GET_TIME_ZONES_RESPONSE_SUCCEDED =
  'profile/GET_TIME_ZONES_RESPONSE_SUCCEDED';
export const GET_TIME_ZONES_FAILED = 'profile/GET_TIME_ZONES_FAILED';

export const getTimeZones = () => async dispatch => {
  dispatch({ type: GET_TIME_ZONES_REQUEST });
  try {
    const response = await api.getTimeZones();
    if (response.status === 200) {
      dispatch({
        type: GET_TIME_ZONES_RESPONSE_SUCCEDED,
        timeZones: response.data,
      });
      return true;
    }
  } catch (e) {
    dispatch({ type: GET_TIME_ZONES_FAILED });
    return false;
  }

  dispatch({ type: GET_TIME_ZONES_FAILED });
  return false;
};

export const CHANGE_USER_PROFILE_REQUEST =
  'profile/CHANGE_USER_PROFILE_REQUEST';
export const CHANGE_USER_PROFILE_RESPONSE_SUCCEDED =
  'profile/CHANGE_USER_PROFILE_RESPONSE_SUCCEDED';
export const CHANGE_USER_PROFILE_FAILED = 'profile/CHANGE_USER_PROFILE_FAILED';

export const changeUserProfile = (userId, userProfile) => async dispatch => {
  dispatch({ type: CHANGE_USER_PROFILE_REQUEST });
  try {
    const response = await api.updateUserProfile(userId, userProfile);
    if (response.status === 204) {
      dispatch({
        type: CHANGE_USER_PROFILE_RESPONSE_SUCCEDED,
        userProfile: userProfile,
      });
      return true;
    }
  } catch (e) {
    if (e.response && e.response.status === 400) {
      dispatch({
        type: CHANGE_USER_PROFILE_FAILED,
        error: e.response.data.description,
      });
      return false;
    }
  }

  dispatch({ type: CHANGE_USER_PROFILE_FAILED, error: 'unknown' });
  return false;
};

export const RESET_PROFILE_FORM_ERROR_REQUEST =
  'profile/RESET_PROFILE_FORM_ERROR_REQUEST';
export const resetProfileError = () => dispatch =>
  dispatch({ type: RESET_PROFILE_FORM_ERROR_REQUEST });
