import React from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'react-bootstrap';
import { FormattedMessage } from 'react-intl';
import Button from '../../../components/Button';

const CreateNewClientSecretConfirmation = ({
  isShown,
  onConfirm,
  onCancel,
  isDisabled,
}) => {
  return (
    <Modal show={isShown} onHide={onCancel}>
      <Modal.Header>
        <Modal.Title>
          <FormattedMessage id="modal.apps.warning" defaultMessage="Warning" />
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <FormattedMessage
          id="apps.createNewClientSecret.confirmation"
          defaultMessage="Creating a new Client Secret will result in automatic expiration of an existing one in 24 hours. This can’t be undone. Please proceed with caution. If you are done with  the changes before old Client Secret expires, you can invalidate it manually by clicking the corresponding button"
        />
      </Modal.Body>
      <Modal.Footer>
        <Button
          className="btn btn-primary"
          disabled={isDisabled}
          type="button"
          onClick={onCancel}
        >
          <FormattedMessage id="button.cancel" />
        </Button>
        <Button
          className="btn btn-danger"
          disabled={isDisabled}
          type="button"
          onClick={onConfirm}
        >
          <FormattedMessage
            id="apps.createNewClientSecret"
            defaultMessage="Create new client secret"
          />
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

CreateNewClientSecretConfirmation.propTypes = {
  isShown: PropTypes.bool,
  isDisabled: PropTypes.bool,
  onConfirm: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
};

export default CreateNewClientSecretConfirmation;
