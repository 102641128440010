// @ts-check
import { NotificationManager } from 'react-notifications';
import {
  getAzureB2CPolicyName,
  getCodeVerifierAndRemoveFromLocalStorage,
  putAuthTokens,
  setAzureB2CAuth,
  setAzureB2CPolicyName,
} from '../localStorage';
import { API, CONFIG_FORM } from './client';
import { AzureB2CConfig } from './config';
import { DEFAULT_LANGUAGE } from './requestInterceptor';
import {
  generateAzureB2CCodeVerifierAndCodeChallenge,
  getCurrentCountryCode,
} from './utils';

class AzureB2CUrlBuilder {
  constructor(tenantId, hostName) {
    this.baseUrl = `https://${hostName}/${tenantId}.onmicrosoft.com`;
    this.scope = `https://${tenantId}.onmicrosoft.com/api/READSYSTEM https://${tenantId}.onmicrosoft.com/api/WRITESYSTEM`;
  }

  buildAuthorizeUrl(policy) {
    return `${this.baseUrl}/${policy}/oauth2/v2.0/authorize`;
  }

  buildTokenUrl(policy) {
    return `${this.baseUrl}/${policy}/oauth2/v2.0/token`;
  }

  buildLogoutUrl() {
    return `${this.baseUrl}/oauth2/v2.0/logout`;
  }
}

class TokenService {
  constructor(urlBuilder, clientId) {
    this.urlBuilder = urlBuilder;
    this.clientId = clientId;
  }

  async issueTokens(code, policyId) {
    const codeVerifier = getCodeVerifierAndRemoveFromLocalStorage();
    const body = new URLSearchParams({
      grant_type: 'authorization_code',
      client_id: this.clientId,
      scope: `${this.urlBuilder.scope}${policyId === AzureB2CConfig.POLICIES.PASSWORD_CHANGE ? ' offline_access' : ''}`,
      code,
      // redirect_uri should be white-listed on the BE side exactly to the same character value
      redirect_uri: `${window.location.origin}/login`,
      code_verifier: codeVerifier,
    });

    return this._makeTokenRequest(policyId, body);
  }

  async reissueTokens(refreshToken) {
    const policyId = getAzureB2CPolicyName();
    const body = new URLSearchParams({
      grant_type: 'refresh_token',
      client_id: this.clientId,
      refresh_token: refreshToken,
    });

    return this._makeTokenRequest(policyId, body);
  }

  async _makeTokenRequest(policyId, body) {
    try {
      return await API.post(
        this.urlBuilder.buildTokenUrl(policyId),
        body,
        CONFIG_FORM,
      );
    } catch (error) {
      NotificationManager.error(`Token operation failed: ${error.message}`);
      throw new Error('Token operation failed');
    }
  }
}

const urlBuilder = new AzureB2CUrlBuilder(
  AzureB2CConfig.TENANT_ID,
  AzureB2CConfig.AZURE_B2C_HOST_NAME,
);
const tokenService = new TokenService(urlBuilder, AzureB2CConfig.CLIENT_ID);

export const getAzureB2CLoginLink = async ({
  redirectTo = '/',
  language = DEFAULT_LANGUAGE,
  authOption = AzureB2CConfig.AUTH_OPTIONS.SIGN_IN,
} = {}) => {
  const { codeChallenge } =
    await generateAzureB2CCodeVerifierAndCodeChallenge();
  const countryCode = getCurrentCountryCode();

  const params = new URLSearchParams({
    client_id: AzureB2CConfig.CLIENT_ID,
    response_type: 'code',
    // redirect_uri should be white-listed on the BE side exactly to the same character value
    redirect_uri: `${window.location.origin}/login`,
    response_mode: 'query',
    scope: `${urlBuilder.scope} offline_access`,
    state: redirectTo,
    lang: language,
    code_challenge: codeChallenge,
    code_challenge_method: 'S256',
    clientId: AzureB2CConfig.MY_UPLINK_CLIENT_ID,
    countryCode: countryCode || '',
    authOption,
  });

  return `${urlBuilder.buildAuthorizeUrl(AzureB2CConfig.POLICIES.LOGIN)}?${params}`;
};

export const loginWithAzureB2CAndRedirect = async (code, redirectTo = '/') => {
  try {
    const response = await issueAzureB2CTokens(
      code,
      AzureB2CConfig.POLICIES.LOGIN,
    );

    if (response.status !== 200) {
      throw new Error('Failed to issue tokens');
    }

    setAzureB2CAuth();
    putAuthTokens(response.data);
    setAzureB2CPolicyName(AzureB2CConfig.POLICIES.LOGIN);

    window.location.href = `${window.location.origin}${redirectTo}`;
  } catch (error) {
    if (
      error.response?.status === 400 &&
      error.response?.data?.error === 'invalid_grant'
    ) {
      NotificationManager.error('Invalid authorization code');
    }
    throw error; // TODO: handle error here, not to re-throw
  }
};

export const getAzureB2CLogoutLink = () => {
  const policyId = getAzureB2CPolicyName();
  const params = new URLSearchParams({
    p: policyId,
    post_logout_redirect_uri: `${window.location.origin}/login`,
    client_id: AzureB2CConfig.CLIENT_ID,
  });

  return `${urlBuilder.buildLogoutUrl()}?${params}`;
};

/**
 * Generates and returns the Azure B2C Change Password link.
 *
 * @async
 * @param {string} language - The language selected by de user.
 * @returns {Promise<string | null>} The Azure B2C Change Password link.
 */
export const getAzureB2CChangePasswordLink = async (
  language = DEFAULT_LANGUAGE,
) => {
  try {
    const { codeChallenge } =
      await generateAzureB2CCodeVerifierAndCodeChallenge();

    const params = new URLSearchParams({
      client_id: AzureB2CConfig.CLIENT_ID,
      response_type: 'code',
      // redirect_uri should be white-listed on the BE side exactly to the same character value
      redirect_uri: window.location.origin + '/profile',
      scope: `${urlBuilder.scope} offline_access`,
      state: '/profile?activeTab=password',
      lang: language || '',
      code_challenge: codeChallenge,
      code_challenge_method: 'S256',
      clientId: AzureB2CConfig.MY_UPLINK_CLIENT_ID,
    });

    return `${urlBuilder.buildAuthorizeUrl(AzureB2CConfig.POLICIES.PASSWORD_CHANGE)}?${params}`;
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error('Failed to generate Azure B2C Change Password link:', error);
    NotificationManager.error('Error generating change password link');
    return null;
  }
};

/**
 * @typedef {Object} AccessToken
 * @property {string} access_token
 * @property {number} expires_in
 * @property {number} expires_on
 * @property {number} not_before
 * @property {string} profile_info
 * @property {string} refresh_token
 * @property {number} refresh_token_expires_in
 * @property {string} resource
 * @property {string} scope
 * @property {string} token_type
 */

export { AzureB2CConfig as AZURE_B2C_CONFIG };
export const issueAzureB2CTokens = tokenService.issueTokens.bind(tokenService);
export const reissueAzureB2CTokens =
  tokenService.reissueTokens.bind(tokenService);
