import React from 'react';
import PropTypes from 'prop-types';
import { FormattedHTMLMessage } from 'react-intl';

const GenericDocument = ({
  id,
  defaultMessage = 'Document is temporary unavailable.',
}) => (
  <p>
    <FormattedHTMLMessage id={id} defaultMessage={defaultMessage} />
  </p>
);

GenericDocument.propTypes = {
  id: PropTypes.string,
};

export default GenericDocument;
