import { connectRouter, routerMiddleware } from 'connected-react-router';
import { createBrowserHistory } from 'history';
import { applyMiddleware, compose, createStore } from 'redux';
import axiosMiddleware from 'redux-axios-middleware';
import reduxImmutableStateInvariant from 'redux-immutable-state-invariant';
import thunk from 'redux-thunk';
import { API } from '../api/client';
import { configNames, getConfigName } from '../api/config';
import { logoutUser } from '../pages/Login/actions';
import { createRootReducers } from './combinedReducers';
export const history = createBrowserHistory();

const logoutReducer = rootReducer => (state, action) => {
  if (action.type === '@@router/LOCATION_CHANGE') {
    if (action.payload.location.pathname === '/logout') {
      let newAction = logoutUser();
      return rootReducer(state, newAction);
    }
  }
  return rootReducer(state, action);
};

function configureStoreProd(initialState) {
  const reactRouterMiddleware = routerMiddleware(history);
  const middlewares = [axiosMiddleware(API), thunk, reactRouterMiddleware];

  return createStore(
    logoutReducer(createRootReducers({ router: connectRouter(history) })),
    initialState,
    compose(applyMiddleware(...middlewares)),
  );
}

function configureStoreDev(initialState) {
  const reactRouterMiddleware = routerMiddleware(history);
  const middlewares = [
    reduxImmutableStateInvariant(),
    axiosMiddleware(API),
    thunk,
    reactRouterMiddleware,
  ];

  const composeEnhancers =
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose; // add support for Redux dev tools
  const store = createStore(
    logoutReducer(createRootReducers({ router: connectRouter(history) })),
    initialState,
    composeEnhancers(applyMiddleware(...middlewares)),
  );

  return store;
}

const configureStore =
  getConfigName() === configNames.DevCloud ||
  getConfigName() === configNames.Local
    ? configureStoreDev
    : configureStoreProd;

export default configureStore;
