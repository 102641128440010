import { Formik } from 'formik';
import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import * as yup from 'yup';
import Button from '../../../../components/Button';
import Input from '../../../../components/Input';
import { formatErrorMessage } from '../../../../localization/message-formatting';

import { isAzureB2CEnabled } from '../../../../components/constants/constants';
import './deleteAccountForm.scss';

const schema = yup.object().shape({
  password: yup.string().required('password.required'),
});

const errorMessages = {
  'password.required': {
    id: 'password.error.validation.required',
    defaultMessage: 'Password is mandatory field',
  },
  'password.incorrect': {
    id: 'password.error.request.invalid',
    defaultMessage: 'Password is invalid. Please try again.',
  },
};

const DeleteAccountForm = ({
  isSubmitting,
  onSubmit,
  requestError,
  intl,
  handleSendConfirmationForDelete,
}) => {
  return (
    <Formik
      initialValues={{ password: '' }}
      validationSchema={schema}
      onSubmit={onSubmit}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
      }) => (
        <form onSubmit={handleSubmit} className="form--half">
          {isAzureB2CEnabled ? (
            <div className="abc-delete-button">
              <FormattedMessage
                id="label.system-profile.delete-account-description"
                defaultMessage="By deleting your personal account, you will lose all personal data and access to your system."
              />
              <div className="button-wrapper">
                <Button
                  disabled={isSubmitting}
                  className="button--danger"
                  type="button"
                  onClick={handleSendConfirmationForDelete}
                >
                  <FormattedMessage
                    id="button.delete"
                    defaultMessage="Delete"
                  />
                </Button>
              </div>
            </div>
          ) : (
            <>
              <Input
                placeholder={intl.formatMessage({ id: 'label.password' })}
                type="password"
                name="password"
                onChange={handleChange}
                onBlur={handleBlur}
                value={values.password}
                error={
                  errors.password &&
                  touched.password &&
                  formatErrorMessage(intl, errorMessages, errors.password)
                }
              />
              {requestError && (
                <p className="text-danger">
                  {formatErrorMessage(intl, errorMessages, requestError)}
                </p>
              )}
              <div className="delete-account button-wrapper">
                <Button className="button--danger" type="submit">
                  <FormattedMessage
                    id="button.delete"
                    defaultMessage="Delete"
                  />
                </Button>
              </div>
              <div className="button-wrapper"></div>
            </>
          )}
        </form>
      )}
    </Formik>
  );
};
DeleteAccountForm.propTypes = {
  isSubmitting: PropTypes.bool.isRequired,
  onSubmit: PropTypes.func.isRequired,
  handleSendConfirmationForDelete: PropTypes.func.isRequired,
  requestError: PropTypes.string,
  intl: PropTypes.object,
};

export default injectIntl(DeleteAccountForm);
