// ts-check
import timeZoneToAlpha2CountryCode from './timeZoneToAlpha2CountryCode';

export const AZURE_B2C_CODE_VERIFIER = 'AZURE_B2C_CODE_VERIFIER';

export const setValueInLocalStorage = (key, value) => {
  if (value !== null) {
    localStorage.setItem(key, JSON.stringify(value));
  }
};

export const getCurrentCountryCode = () => {
  const { timeZone } = Intl.DateTimeFormat().resolvedOptions();

  return timeZoneToAlpha2CountryCode[timeZone];
};

/**
 * Generates a random code as a string with the specified length.
 *
 * @param {number} [length=32] - The length of the random code to generate.
 * @returns {string} A random code.
 */
export const randomCode = (length = 32) => {
  let array = new Uint8Array(length);
  array = crypto.getRandomValues(array);
  return String.fromCharCode.apply(null, Array.from(array));
};

/**
 * Encodes a string to Base64 URL-safe format.
 *
 * @param {string} str - The string to be encoded.
 * @returns {string} The Base64 URL-safe encoded string.
 */
const base64URLEncode = str => {
  const base64 = btoa(str);
  const base64URL = base64
    .replace(/\+/g, '-')
    .replace(/\//g, '_')
    .replace(/=/g, '');
  return base64URL;
};

/**
 * Generates a code verifier for Azure B2C authentication flow.
 *
 * @returns {string} The code verifier string.
 */
const generateAzureB2CCodeVerifier = () => {
  return base64URLEncode(randomCode());
};

/**
 * Generates a code challenge for Azure B2C authentication flow based on a code verifier.
 *
 * @async
 * @param {string} codeVerifier - The code verifier to generate the challenge from.
 * @returns {Promise<string>} The code challenge string.
 */
const generateAzureB2CCodeChallenge = async codeVerifier => {
  const sha256Hash = await crypto.subtle.digest(
    { name: 'SHA-256' },
    new TextEncoder().encode(codeVerifier),
  );
  const uIntArray = new Uint8Array(sha256Hash);
  const numberArray = Array.from(uIntArray);
  const hashString = String.fromCharCode.apply(null, numberArray);
  return base64URLEncode(hashString);
};

/**
 * Generates an Azure B2C code verifier and its corresponding code challenge.
 *
 * @async
 * @returns {Promise<{ codeVerifier: string, codeChallenge: string }>} An object containing the code verifier and code challenge.
 */
export const generateAzureB2CCodeVerifierAndCodeChallenge = async () => {
  try {
    const codeVerifier = generateAzureB2CCodeVerifier();
    setValueInLocalStorage(AZURE_B2C_CODE_VERIFIER, codeVerifier);

    const codeChallenge = await generateAzureB2CCodeChallenge(codeVerifier);

    return {
      codeVerifier,
      codeChallenge,
    };
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error('Failed to generate code verifier and challenge:', e);
    throw e;
  }
};
