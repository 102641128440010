import React from 'react';
import './input.scss';
import PropTypes from 'prop-types';

class StatelessInput extends React.Component {
  static propTypes = {
    title: PropTypes.string,
    id: PropTypes.string,
    name: PropTypes.string,
    type: PropTypes.string,
    value: PropTypes.string,
    placeholder: PropTypes.string,
    autocomplete: PropTypes.string,
    role: PropTypes.string,
    label: PropTypes.string,
    error: PropTypes.string,
    onChange: PropTypes.func,
    isReadOnly: PropTypes.bool,
  };

  static defaultProps = {
    onChange() {},
  };

  handleChange = e => {
    const { props } = this;

    props.onChange({
      target: {
        ...props,
        type: e.target.type,
        value: e.target.value,
      },
      stopPropagation() {
        e.stopPropagation();
      },
      preventDefault() {
        e.preventDefault();
      },
      nativeEvent: e.nativeEvent,
    });
  };

  render() {
    const {
      id,
      name,
      type,
      placeholder,
      autocomplete,
      role,
      label,
      error,
      value,
    } = this.props;

    return (
      <div
        className={
          'inputComponent' + (error ? ' inputComponent--has-error' : '')
        }
      >
        {label ? (
          <label className="inputComponent__label" htmlFor={id}>
            {label}
          </label>
        ) : (
          ''
        )}

        {error ? <p className="inputComponent__error">{error}</p> : ''}

        <label
          htmlFor={this.props.id}
          className="inputComponent__placeholder__label"
        >
          <span
            className={
              'inputComponent__placeholder' +
              (value && value.length ? ' focused' : '')
            }
          >
            {placeholder}
          </span>
          <input
            className={
              'inputComponent__field' +
              (error ? ' inputComponent__field--error' : '')
            }
            id={id}
            name={name}
            type={type}
            autoComplete={autocomplete}
            role={role}
            value={value}
            readOnly={this.props.isReadOnly}
            onChange={e => this.handleChange(e)}
          />
        </label>
      </div>
    );
  }
}

export default StatelessInput;
