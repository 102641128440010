import PropTypes from 'prop-types';
import React from 'react';
import { FormattedHTMLMessage } from 'react-intl';

import './style.scss';

const ChangelogTab = ({ isProPage }) => (
  <div>
    <p className="changelog-title">
      <FormattedHTMLMessage
        id={'label.changeLogs.text'}
        defaultMessage={
          'The primary purpose of this changelog is to inform API developers of any breaking changes, ensuring seamless integration and usage of the API. The lack of updates to the changelog does not, in and of itself, mean that there has been no improvements made; only that no breaking changes has been introduced.'
        }
      />
    </p>
    <table className="changelog-table">
      <thead>
        <tr>
          <th>
            <FormattedHTMLMessage
              id={'label.version'}
              defaultMessage="Version"
            />
          </th>
          <th>
            <FormattedHTMLMessage
              id={'label.releaseDate'}
              defaultMessage="Release Date"
            />
          </th>
          <th>
            <FormattedHTMLMessage
              id={'label.releaseNotes'}
              defaultMessage="Release Notes"
            />
          </th>
        </tr>
      </thead>
      <tbody>
        {isProPage ? (
          <tr>
            <td>v0.9</td>
            <td>November, 2024</td>
            <td>Initial beta release of the public myUplink API.</td>
          </tr>
        ) : (
          <tr>
            <td>v1.0</td>
            <td>December, 2019</td>
            <td>Initial release of the public myUplink API.</td>
          </tr>
        )}
      </tbody>
    </table>
  </div>
);

ChangelogTab.propTypes = {
  isProPage: PropTypes.bool.isRequired,
};

export default ChangelogTab;
