export const preventGoogleFontsLoading = () => {
  const head = document.getElementsByTagName('head')[0];

  // Save the original method
  const insertBefore = head.insertBefore;

  // Replace it!
  // @ts-ignore
  head.insertBefore = function (newElement, referenceElement) {
    // @ts-ignore
    if (
      newElement.href &&
      newElement.href.includes('https://fonts.googleapis.com/css')
    ) {
      // if (newElement.href && newElement.href.indexOf('https://fonts.googleapis.com/css?family=Roboto') === 0) {
      return;
    }

    insertBefore.call(head, newElement, referenceElement);
  };
};
