import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Redirect, Route, Switch, withRouter } from 'react-router-dom';
import { compose } from 'redux';

import Apps from '../pages/Apps';
import Auth from '../pages/Auth';
import ForgotPassword from '../pages/ForgotPassword';
import Intro from '../pages/Intro';
import APIServicesAgreement from '../pages/LegalPages/APIServicesAgreement';
import PrivacyPolicy from '../pages/LegalPages/PrivacyPolicy';
import TermsOfService from '../pages/LegalPages/TermsOfService';
import Login from '../pages/Login';
import NotFoundPage from '../pages/NotFound';
import Parameters from '../pages/Parameters';
import Updates from '../pages/Updates';
import SignInLayoutRoute from './SignInLayout';
import SignOutLayoutRoute from './SignOutLayout';
//Hiding because some links for tutorials are not ready yet but will be use in future
// import Tutorial from '../pages/Tutorial/Tutorial';
import Landing from '../pages/Landing';
import ProfilePage from '../pages/ProfilePage';
import Swagger from '../pages/Swagger';

import { ROUTES } from '../constants';
import events, { eventEmitter } from '../events';

const routesDefinition = {
  anonymousRoutes: [
    {
      path: ROUTES.LOGIN,
      component: <SignOutLayoutRoute path={ROUTES.LOGIN} component={Login} />,
    },
    {
      path: ROUTES.FORGOT_PASSWORD,
      component: (
        <SignOutLayoutRoute
          path={ROUTES.FORGOT_PASSWORD}
          component={ForgotPassword}
        />
      ),
    },
    {
      path: ROUTES.PRIVACY_POLICY,
      component: (
        <SignOutLayoutRoute
          path={ROUTES.PRIVACY_POLICY}
          component={PrivacyPolicy}
        />
      ),
    },
    {
      path: ROUTES.TERMS_OF_SERVICE,
      component: (
        <SignOutLayoutRoute
          path={ROUTES.TERMS_OF_SERVICE}
          component={TermsOfService}
        />
      ),
    },
    {
      path: ROUTES.API_SERVICES_AGREEMENT,
      component: (
        <SignOutLayoutRoute
          path={ROUTES.API_SERVICES_AGREEMENT}
          component={APIServicesAgreement}
        />
      ),
    },
  ],
  restrictedRoutes: [
    {
      path: ROUTES.ROOT,
      component: (
        <SignInLayoutRoute path={ROUTES.ROOT} exact component={Landing} />
      ),
    },
    {
      path: ROUTES.APPLICATIONS,
      component: (
        <SignInLayoutRoute path={ROUTES.APPLICATIONS} component={Apps} />
      ),
    },
    {
      path: ROUTES.DOCUMENTATION.INTRO,
      component: (
        <SignInLayoutRoute
          path={ROUTES.DOCUMENTATION.INTRO}
          component={Intro}
        />
      ),
    },
    //Hiding because some links for tutorials are not ready yet but will be use in future
    // { path: '/tutorial', component: <SignInLayoutRoute path="/tutorial" component={Tutorial} /> },
    {
      path: ROUTES.DOCUMENTATION.AUTH,
      component: (
        <SignInLayoutRoute path={ROUTES.DOCUMENTATION.AUTH} component={Auth} />
      ),
    },
    {
      path: ROUTES.DOCUMENTATION.PARAMETERS,
      component: (
        <SignInLayoutRoute
          path={ROUTES.DOCUMENTATION.PARAMETERS}
          component={Parameters}
        />
      ),
    },
    {
      path: ROUTES.DOCUMENTATION.UPDATES,
      component: (
        <SignInLayoutRoute
          path={ROUTES.DOCUMENTATION.UPDATES}
          component={Updates}
        />
      ),
    },
    {
      path: ROUTES.PRIVACY_POLICY,
      component: (
        <SignInLayoutRoute
          path={ROUTES.PRIVACY_POLICY}
          component={PrivacyPolicy}
        />
      ),
    },
    {
      path: ROUTES.TERMS_OF_SERVICE,
      component: (
        <SignInLayoutRoute
          path={ROUTES.TERMS_OF_SERVICE}
          component={TermsOfService}
        />
      ),
    },
    {
      path: ROUTES.API_SERVICES_AGREEMENT,
      component: (
        <SignInLayoutRoute
          path={ROUTES.API_SERVICES_AGREEMENT}
          component={APIServicesAgreement}
        />
      ),
    },
    {
      path: ROUTES.PROFILE_SETTINGS,
      component: (
        <SignInLayoutRoute
          path={ROUTES.PROFILE_SETTINGS}
          component={ProfilePage}
        />
      ),
    },
    {
      path: ROUTES.PRO_DOCUMENTATION.INTRO,
      component: (
        <SignInLayoutRoute
          path={ROUTES.PRO_DOCUMENTATION.INTRO}
          component={Intro}
        />
      ),
    },
    {
      path: ROUTES.PRO_DOCUMENTATION.AUTH,
      component: (
        <SignInLayoutRoute
          path={ROUTES.PRO_DOCUMENTATION.AUTH}
          component={Auth}
        />
      ),
    },
    {
      path: ROUTES.PRO_DOCUMENTATION.PARAMETERS,
      component: (
        <SignInLayoutRoute
          path={ROUTES.PRO_DOCUMENTATION.PARAMETERS}
          component={Parameters}
        />
      ),
    },
    {
      path: ROUTES.PRO_DOCUMENTATION.UPDATES,
      component: (
        <SignInLayoutRoute
          path={ROUTES.PRO_DOCUMENTATION.UPDATES}
          component={Updates}
        />
      ),
    },
    {
      path: ROUTES.PRO_DOCUMENTATION.SWAGGER,
      component: (
        <SignInLayoutRoute
          path={ROUTES.PRO_DOCUMENTATION.SWAGGER}
          component={Swagger}
        />
      ),
    },
  ],
};

const Router = ({ match, location, history, userLoggedIn }) => {
  const routes = userLoggedIn
    ? routesDefinition.restrictedRoutes
    : routesDefinition.anonymousRoutes;

  const redirectTo =
    !userLoggedIn && !match.isExact
      ? '?redirectTo=' + location.pathname + (location.search || '')
      : '';

  const handleTabChange = tab => {
    history.push(tab);
  };

  useEffect(() => {
    eventEmitter.addListener(events.inApp.routing.CHANGE_TAB, handleTabChange);

    return () => {
      eventEmitter.removeListener(
        events.inApp.routing.CHANGE_TAB,
        handleTabChange,
      );
    };
  }, []);

  return (
    <Switch>
      {routes.map((r, i) => ({ ...r.component, key: i }))}
      <Redirect to={userLoggedIn ? ROUTES.ROOT : ROUTES.LOGIN + redirectTo} />
      <Route component={NotFoundPage} />
    </Switch>
  );
};

Router.propTypes = {
  match: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  userLoggedIn: PropTypes.bool.isRequired,
};

const withConnect = connect(({ app }) => ({ ...app }));

export default compose(withRouter, withConnect)(Router);
