import React from 'react';
import PropTypes from 'prop-types';
import Router from './Routes';

import AppInitializer from './AppInitializer';
import ErrorBoundary from '../pages/ErrorBoundary';

import { NotificationContainer } from 'react-notifications';

import './app.scss';

class App extends React.Component {
  render() {
    return (
      <ErrorBoundary>
        <NotificationContainer />
        <AppInitializer>
          <Router />
        </AppInitializer>
      </ErrorBoundary>
    );
  }
}

App.propTypes = {
  children: PropTypes.element,
};

export default App;
