import React from 'react';
import FooterMenu from '../Footer/components';
import './footer.scss';

const Footer = () => {
  const links = [
    {
      path: '/terms-of-service',
      id: 'footer.termsOfService',
      name: 'Terms of service',
    },
    {
      path: '/privacy-policy',
      id: 'footer.privacyPolicy',
      name: 'Privacy policy',
    },
    {
      path: '/api-services-agreement',
      id: 'footer.apiServicesAgreement',
      name: 'API Services Agreement',
    },
  ];

  const menuItems = links;

  return (
    <footer className="footer-container">
      <div className="row">
        <div className="footer-links">
          <FooterMenu menuItems={menuItems} />
        </div>
      </div>
    </footer>
  );
};

export default Footer;
