import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import Spinner from '../../components/Spinner';
import Title from '../../components/Title';

import { getAzureB2CChangePasswordLink } from '../../api/azureB2CService';
import {
  changeRedirectStatus,
  getUserInfo,
} from '../../components/AppInitializer/actions';
import {
  getActiveTabFromQueryString,
  getRedirectToFromQueryParams,
  selectTab as selectTabViaURL,
} from '../../utils';
import useSelectedTab from '../hooks/useSelectedTab';
import PageContentWrapper from '../PageContentWrapper';
import {
  changePassword,
  deleteAccount,
  resetDeleteError,
  resetEmailError,
  validateUserCredentials,
} from './actions';
import ChangePasswordForm from './components/ChangePasswordForm';
import DeleteAccountTab from './components/DeleteAccountTab/DeleteAccountTab';
import ProfileTab from './components/ProfileTab/ProfileTab';
import './profilePage.scss';

export const PROFILE_TABS = {
  PROFILE: 'profile',
  PASSWORD: 'password',
  DELETE: 'delete',
};

export const DEFAULT_TAB = PROFILE_TABS.PROFILE;

const ProfilePage = ({
  userInfo,
  getUserInfo,
  location,
  profileTab,
  profilePage,
  history,
}) => {
  const selectedTab = getActiveTabFromQueryString(location);

  const { isLoadingTab, setIsLoadingTab } = useSelectedTab(
    selectedTab,
    DEFAULT_TAB,
    PROFILE_TABS,
  );

  const [state, setState] = useState({
    isLoaded: false,
    isEmailEditShown: false,
    isEmailConfirmationSent: false,
    isAddressEditShown: false,
    isPasswordChanged: false,
  });

  const selectTab = tab => {
    const isDemo = (userInfo && userInfo.isDemo) || false;

    if (!isDemo) {
      selectTabViaURL(tab);
      setIsLoadingTab(true);
    }
  };

  const changePasswordWithAzureB2C = async () => {
    const redirectLink = await getAzureB2CChangePasswordLink();
    if (redirectLink) {
      window.location.href = redirectLink;
    }
  };

  const init = async () => {
    await getUserInfo();
    setState({ ...state, isLoaded: true });

    const redirectTo = getRedirectToFromQueryParams();

    if (redirectTo) {
      return history.push(redirectTo);
    }
  };

  useEffect(() => {
    init();
  }, []);

  const getCurrentTab = () => {
    switch (selectedTab) {
      case PROFILE_TABS.PROFILE:
        return (
          <ProfileTab
            userInfo={userInfo}
            addressId={userInfo.addressId}
            requestError={profileTab.profileError}
          />
        );
      case PROFILE_TABS.PASSWORD:
        return (
          <ChangePasswordForm
            requestError={profilePage.passwordError}
            isChanged={state.isPasswordChanged}
            onSubmit={onPasswordSubmit}
            changePasswordWithAzureB2C={changePasswordWithAzureB2C}
          />
        );
      case PROFILE_TABS.DELETE:
        return (
          <DeleteAccountTab
            deleteError={profilePage.deleteError}
            deleteUser={deleteAccount}
            resetDeleteError={resetDeleteError}
            userId={userInfo.id}
            userEmail={userInfo.email}
            history={history}
            validatePasswordError={profilePage.validatePasswordError}
            validateUserCredentials={validateUserCredentials}
            changeRedirectStatus={changeRedirectStatus}
          />
        );
      default:
        return null;
    }
  };

  const onPasswordSubmit = async (values, { setSubmitting, resetForm }) => {
    setSubmitting(true);
    if (
      await changePassword(
        userInfo.id,
        values.oldPassword.trim(),
        values.newPassword.trim(),
      )
    ) {
      setState({ ...state, isPasswordChanged: true });
      resetForm();
      setSubmitting(false);
      setTimeout(() => {
        setState({ ...state, isPasswordChanged: false });
      }, 2000);
    } else {
      setSubmitting(false);
    }
  };

  const isDemo = userInfo && userInfo.isDemo;
  const isDisabledClassName = isDemo ? 'disabled' : '';

  if (!state.isLoaded) {
    return <Spinner />;
  }

  return (
    <div className="page-content">
      <div className="profile-page">
        <Title
          titleTranslationId="profile-settings.title"
          defaultMessage="Profile Settings"
        />
        <div className="tabordion">
          <section className="profile-section" id="profile-section">
            <div
              onClick={() => selectTab(PROFILE_TABS.PROFILE)}
              className={
                selectedTab === PROFILE_TABS.PROFILE
                  ? 'option-label selected'
                  : 'option-label'
              }
            >
              <FormattedMessage id={'label.profile'} defaultMessage="Profile" />
            </div>
            <div
              onClick={() => selectTab(PROFILE_TABS.PASSWORD)}
              className={classNames(
                selectedTab === PROFILE_TABS.PASSWORD
                  ? 'option-label selected'
                  : 'option-label',
                isDisabledClassName,
              )}
            >
              <FormattedMessage
                id={'label.password'}
                defaultMessage="Password"
              />
            </div>
            <div
              onClick={() => selectTab(PROFILE_TABS.DELETE)}
              className={classNames(
                selectedTab === PROFILE_TABS.DELETE
                  ? 'option-label selected'
                  : 'option-label',
                isDisabledClassName,
              )}
            >
              <FormattedMessage
                id={'label.delete'}
                defaultMessage="Remove account"
              />
            </div>
          </section>
          <div className="tab-content">
            <PageContentWrapper
              location={location}
              availableTabs={PROFILE_TABS}
              isLoadingTab={isLoadingTab}
            >
              {getCurrentTab()}
            </PageContentWrapper>
          </div>
        </div>
      </div>
    </div>
  );
};

ProfilePage.propTypes = {
  getUserInfo: PropTypes.func.isRequired,
  changePassword: PropTypes.func.isRequired,
  resetEmailError: PropTypes.func.isRequired,
  deleteAccount: PropTypes.func.isRequired,
  resetDeleteError: PropTypes.func.isRequired,
  history: PropTypes.object,
  profilePage: PropTypes.object,
  userInfo: PropTypes.object,
  validateUserCredentials: PropTypes.func.isRequired,
  changeRedirectStatus: PropTypes.func.isRequired,
  profileTab: PropTypes.object,
};

export default connect(
  ({ app: { userInfo }, profilePage, profileTab }) => ({
    userInfo,
    profilePage,
    profileTab,
  }),
  {
    getUserInfo,
    changePassword,
    resetEmailError,
    deleteAccount,
    resetDeleteError,
    validateUserCredentials,
    changeRedirectStatus,
  },
)(ProfilePage);
