import React from 'react';
import { FormattedHTMLMessage } from 'react-intl';
import BaseLegalPage from './BaseLegalPage';

const APIServicesAgreement = props => (
  <BaseLegalPage
    {...props}
    content={<FormattedHTMLMessage id="api-services-agreement" />}
  />
);

export default APIServicesAgreement;
