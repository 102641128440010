import React from 'react';
import { FormattedMessage } from 'react-intl';

import GenericDocument from '../Intro/components/GenericDocument';
import {
  getActiveTabFromQueryString,
  getDocumentNameIdPrefix,
  selectTab as selectTabViaURL,
} from '../../utils';
import PageContentWrapper from '../PageContentWrapper';
import useSelectedTab from '../hooks/useSelectedTab';

import 'react-tabs/style/react-tabs.css';
import './parameters.scss';
import PropTypes from 'prop-types';

export const PARAMETERS_TABS = {
  PARAMETERS: 'parameters',
  DATA_POINTS: 'data-points',
  SETTINGS: 'settings',
};

const Parameters = ({ location }) => {
  const selectedTab = getActiveTabFromQueryString(location);

  const { isLoadingTab, setIsLoadingTab } = useSelectedTab(
    selectedTab,
    PARAMETERS_TABS.PARAMETERS,
    PARAMETERS_TABS,
  );

  const selectTab = index => {
    setIsLoadingTab(true);
    selectTabViaURL(index);
  };

  const getCurrentTab = () => {
    const prefix = getDocumentNameIdPrefix(location?.pathname ?? '');

    switch (selectedTab) {
      case PARAMETERS_TABS.PARAMETERS:
        return (
          // Default message is removed because it renders default message instead of the actual message id
          <GenericDocument
            id={`${prefix}wiki.${PARAMETERS_TABS.PARAMETERS}`}
            defaultMessage=""
          />
        );
      case PARAMETERS_TABS.DATA_POINTS:
        return (
          <GenericDocument
            id={`${prefix}wiki.${PARAMETERS_TABS.DATA_POINTS}`}
          />
        );
      case PARAMETERS_TABS.SETTINGS:
        return (
          <GenericDocument id={`${prefix}wiki.${PARAMETERS_TABS.SETTINGS}`} />
        );
      default:
        return null;
    }
  };

  return (
    <div className="nibe-parameters">
      <div className="row">
        <div className="tabordion" tabIndex="-1">
          <section id="nibe-parameters-section" tabIndex="0">
            <div
              onClick={() => selectTab(PARAMETERS_TABS.PARAMETERS)}
              tabIndex="0"
              className={
                selectedTab === PARAMETERS_TABS.PARAMETERS
                  ? 'option-label selected'
                  : 'option-label'
              }
            >
              <FormattedMessage
                id={'label.parameters'}
                defaultMessage="Parameter"
              />
            </div>
            <div
              onClick={() => selectTab(PARAMETERS_TABS.DATA_POINTS)}
              tabIndex="0"
              className={
                selectedTab === PARAMETERS_TABS.DATA_POINTS
                  ? 'option-label selected'
                  : 'option-label'
              }
            >
              <FormattedMessage
                id={'label.data-points'}
                defaultMessage="Data Points"
              />
            </div>
            <div
              onClick={() => selectTab(PARAMETERS_TABS.SETTINGS)}
              tabIndex="0"
              className={
                selectedTab === PARAMETERS_TABS.SETTINGS
                  ? 'option-label selected'
                  : 'option-label'
              }
            >
              <FormattedMessage
                id={'label.settings'}
                defaultMessage="Settings"
              />
            </div>
          </section>
          <div className="tab-content" tabIndex="0">
            <PageContentWrapper
              location={location}
              availableTabs={PARAMETERS_TABS}
              isLoadingTab={isLoadingTab}
            >
              {getCurrentTab()}
            </PageContentWrapper>
          </div>
        </div>
      </div>
    </div>
  );
};

Parameters.propTypes = {
  location: PropTypes.object,
};

export default Parameters;
