import React from 'react';
import { Link } from '../../../../../Link';
import PropTypes from 'prop-types';
import { FormattedMessage } from 'react-intl';
import './desktop-menu.scss';
import { NavLink } from 'react-router-dom';

const DesktopSubMenu = ({ subMenuItems, align }) => {
  return (
    <div
      className={
        'desktopSubMenu' +
        (align === 'right' ? ' desktopSubMenu--expand-left' : '')
      }
    >
      <div className="desktopSubMenu__list">
        {subMenuItems &&
          subMenuItems
            .filter(item => !item.hidden)
            .map(val =>
              val.url ? (
                <div key={val.id} className="desktopSubMenu__link">
                  <a className="url" href={val.url}>
                    <FormattedMessage id={val.id} defaultMessage={val.name} />
                  </a>
                </div>
              ) : val.disabled ? (
                <div key={val.id} className="desktopSubMenu__link-disabled">
                  <FormattedMessage id={val.id} defaultMessage={val.name} />
                </div>
              ) : (
                <Link
                  key={val.id}
                  className="desktopSubMenu__link"
                  to={val.disabled ? '' : val.path}
                >
                  <FormattedMessage id={val.id} defaultMessage={val.name} />
                </Link>
              ),
            )}
      </div>
    </div>
  );
};

DesktopSubMenu.propTypes = {
  subMenuItems: PropTypes.array,
  align: PropTypes.string,
};

const DesktopMenuItem = ({
  id,
  name,
  children,
  align,
  useIcon,
  icon,
  path,
  action,
}) => {
  const customStyle = {
    backgroundImage: 'url(' + icon + ')',
    backgroundSize: 'contain',
  };
  const alignClass = align === 'right' ? ' desktopMenu__item--right' : '';

  return (
    <li id={id} className={'desktopMenu__item' + alignClass} onClick={action}>
      {!useIcon ? (
        <NavLink className="desktopMenu__itemLink" to={path}>
          <FormattedMessage id={id} defaultMessage={name} />
          {children && <span className="caret"></span>}
        </NavLink>
      ) : (
        <a
          style={customStyle}
          className={'desktopMenu__itemLink desktopMenu__itemLink--icon'}
        ></a>
      )}

      <DesktopSubMenu subMenuItems={children} align={align} />
    </li>
  );
};

DesktopMenuItem.propTypes = {
  id: PropTypes.string,
  name: PropTypes.string,
  children: PropTypes.array,
  align: PropTypes.string,
  useIcon: PropTypes.bool,
  icon: PropTypes.string,
  path: PropTypes.string,
  action: PropTypes.func,
};

const DesktopMenu = ({ menuItems }) => {
  return (
    <nav className="desktopMenu">
      <ul className="desktopMenu__list">
        {menuItems.map((val, index) => (
          <DesktopMenuItem key={index} {...val} />
        ))}
      </ul>
    </nav>
  );
};

DesktopMenu.propTypes = {
  menuItems: PropTypes.array,
};

export default DesktopMenu;
