import { Modal, ModalBody } from 'react-bootstrap';
import { FormattedHTMLMessage } from 'react-intl';
import PropTypes from 'prop-types';
import React from 'react';

const LegalInfoModal = ({ isShow, onHide }) => {
  return (
    <Modal show={isShow} backdrop={true} onHide={onHide}>
      <ModalBody>
        <div id="legalText">
          <FormattedHTMLMessage id="api-services-agreement" />
        </div>
      </ModalBody>
    </Modal>
  );
};

LegalInfoModal.propTypes = {
  isShow: PropTypes.bool,
  onHide: PropTypes.func.isRequired,
};

export default LegalInfoModal;
