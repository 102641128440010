import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import { NotificationManager } from 'react-notifications';
import { Link } from 'react-router-dom';

import { loginWithAzureB2CAndRedirect } from '../../api/azureB2CService';
import Button from '../../components/Button';
import envelopeImage from './assets/envelope.svg';
import { loginWithAzureB2C } from './utils';

import Spacer from '../../components/Spacer';
import { parseNullishStringifiedValuesToUndefined } from '../../utils';
import './login.scss';

export const LoginAB2C = ({ intl }) => {
  useEffect(() => {
    const handleAuthCallback = async () => {
      const queryParams = new URLSearchParams(window.location.search);
      const codeParam = queryParams.get('code');
      const errorDescriptionParam = queryParams.get('error_description');
      const stateParam = parseNullishStringifiedValuesToUndefined(
        queryParams.get('state'),
      );

      if (codeParam) {
        try {
          await loginWithAzureB2CAndRedirect(codeParam, stateParam);
        } catch (error) {
          NotificationManager.error(
            <FormattedMessage
              id="generic.error.request.unknown"
              defaultMessage="An error has occurred. Try again later."
            />,
          );
        }
      } else if (errorDescriptionParam) {
        NotificationManager.error(errorDescriptionParam);
      }
    };

    handleAuthCallback();

    document.title = intl.formatMessage({
      id: 'login.page-title',
      defaultMessage:
        'myUplink - Login to connect and manage your compatible smart home energy systems', // TODO: Change for DEV app
    });
  }, []);

  return (
    <div className="Login abc-login">
      <h1 className="abc-login-title">
        <FormattedMessage
          id="login.header.variant"
          defaultMessage="Welcome back!"
        />
      </h1>
      <Spacer y={40} />
      <Button
        type="button"
        className="link--secondary button--secondary login-ab2c-button"
        onClick={loginWithAzureB2C}
      >
        <img src={envelopeImage} alt="envelope" />
        <FormattedMessage
          id="login.button.text.variant"
          defaultMessage="Continue with email"
        />
      </Button>
      <Spacer y={100} />
      <div className="abc-login-tos">
        <FormattedMessage
          id="login.acknowledge"
          values={{
            termsLink: <Link to="/terms-of-service">Terms & Conditions</Link>,
            privacyLink: <Link to="/privacy-policy">Privacy Policy</Link>,
          }}
        />
      </div>
    </div>
  );
};

LoginAB2C.propTypes = {
  intl: PropTypes.object.isRequired,
};

export default injectIntl(LoginAB2C);
