import React from 'react';

import Breadcrumbs from '../../components/Breadcrumbs';
import { getBreadcrumbPathAndActiveTabInQueryString } from '../../utils';
import Spinner from '../../components/Spinner';
import Fade from '../../components/Fade';

const PageContentWrapper = ({
  children,
  location,
  availableTabs,
  isLoadingTab,
}) =>
  isLoadingTab ? (
    <Fade>
      <Spinner />
    </Fade>
  ) : (
    <div className="page-content-wrapper">
      <Breadcrumbs
        path={getBreadcrumbPathAndActiveTabInQueryString(location)}
        availableTabs={availableTabs}
      />
      <div className="main-content">{children}</div>
    </div>
  );

export default PageContentWrapper;
