import React, { useEffect } from 'react';
import { isSelectedTabInAvailableTabs, selectTab } from '../../utils';

const shouldSetToDefaultTab = (selectedTab, availableTabs) =>
  (selectedTab !== 0 && !selectedTab) ||
  !isSelectedTabInAvailableTabs(selectedTab, availableTabs);

const useSelectedTab = (selectedTab, defaultTab, availableTabs) => {
  const [isLoadingTab, setIsLoadingTab] = React.useState(false);

  useEffect(() => {
    if (shouldSetToDefaultTab(selectedTab, availableTabs)) {
      selectTab(defaultTab);
    }
    setIsLoadingTab(false);
  }, [selectedTab]);

  return { isLoadingTab, setIsLoadingTab };
};

export default useSelectedTab;
