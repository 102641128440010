import React from 'react';
import { Link } from '../Link';
import myUplinkDevLogo from '../../assets/img/myuplinkdevs.png';
import './logo.scss';

const Logo = () => (
  <Link className="logo" to={'/'} tabIndex={0}>
    <img src={myUplinkDevLogo} alt="This is myUplink Developer logo" />
  </Link>
);

export default Logo;
