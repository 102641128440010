export const phone = {
  regex: /^(\+)?[\d\-)(]+$/,
  minLength: 5,
  maxLength: 25,
};

export const address = {
  postalCode: {
    regex: /^[a-zA-Z\d\- ]+$/,
    maxLength: 20,
  },
  cityNameMaxLength: 100,
  regionNameMaxLength: 100,
  pattern: /^[\p{L}\p{Nd}'"\-–.,#&/() ]+$/u,
};

export const emailAddress = {
  maxLength: 255,
  antiXssRegex: /^[^%<>^$'`*|{}="]+$/,
};

export const timeZoneOffsetRegex = /\(([^)]+)\)/;
export const externalLinkRegex = /^https?:\/\//;
export const callbackURLRegex =
  /^https?:\/\/[\w-]+(\.[\w-]+)*(:[0-9]+)?(\/.*)?$/;
export const userNameRegex = /^[\p{L}\p{Nd}'\-. ]+$/u;
export const parameterInvalidValue = -32760;
export const dateTimeFormatString = 'YYYYMMDDTHHmmssZ';
export const defaultMaxLength = 255;
export const passwordMaxLength = 128;
export const BRANDS_WITH_FREE_SERVICES = ['CTC'];

export const BRANDS_WITH_ECOM = ['NIBE', 'NIBEF'];
export const FaqQuestionType = {
  Regular: 'regular',
  Custom: 'custom',
};

export const availableLocales = {
  Czech: 'cs',
  Danish: 'da',
  Dutch: 'nl',
  English: 'en',
  Estonian: 'et',
  Finnish: 'fi',
  French: 'fr',
  German: 'de',
  Hungarian: 'hu',
  Italian: 'it',
  Norwegian: 'nb',
  Polish: 'pl',
  Romanian: 'ro',
  Russian: 'ru',
  Slovak: 'sk',
  Slovenian: 'sl',
  Spanish: 'es',
  Swedish: 'sv',
};

export const noRegion = { name: 'None', code: 'NONE' };

export const isAzureB2CEnabled =
  process.env.REACT_APP_AZURE_B2C_ENABLED === 'true';
