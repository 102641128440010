import { ROUTES } from '../constants';
import events, { eventEmitter } from '../events';
import { DEFAULT_TAB } from '../pages/ProfilePage/ProfilePage';

export const isProPage = pathname => {
  return pathname?.includes('pro');
};

export const getDocumentNameIdPrefix = pathname => {
  return isProPage(pathname) ? 'pro.' : '';
};

export const doesLocationIncludeRedirectTo = location =>
  !!getSearchParamsByKey(location, 'redirectTo');

export const doesLocationIncludeRedirectToManually = location => {
  return location.search.includes('redirectTo');
};

export const getSearchParams = location => new URLSearchParams(location.search);

export const getSearchParamsByKey = (location, key) =>
  getSearchParams(location).get(key);

export const getRedirectTo = location =>
  getSearchParamsByKey(location, 'redirectTo');

export const getRedirectLocation = location =>
  getRedirectTo(location) || ROUTES.ROOT;

export const getActiveTabFromQueryString = location =>
  getSearchParamsByKey(location, 'activeTab') || DEFAULT_TAB;

export const getBreadcrumbPathAndActiveTabInQueryString = location => {
  const activeTab = getActiveTabFromQueryString(location);
  const slugs = location.pathname.split('/').filter(Boolean);
  return { slugs, activeTab };
};

export const parseLocationSlugToWords = slug => {
  return slug
    .split('-')
    .map(word => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ');
};

export const selectTab = tab => {
  eventEmitter.emit(events.inApp.routing.CHANGE_TAB, '?activeTab=' + tab);
};

export const isSelectedTabInAvailableTabs = (selectedTab, availableTabs) =>
  Object.values(availableTabs).includes(selectedTab);

export const isSelectedTabInExistingSlugs = (selectedTab, slugs) =>
  slugs.includes(selectedTab);

export const parseNullishStringifiedValuesToUndefined = value =>
  value === 'null' || value === 'undefined' || !value?.trim()
    ? undefined
    : value;

export const getRedirectToFromQueryParams = () => {
  const queryParams = new URLSearchParams(window.location.search);
  const redirectTo = parseNullishStringifiedValuesToUndefined(
    queryParams.get('state'),
  );
  return redirectTo;
};
