import React from 'react';
import { FormattedMessage } from 'react-intl';
import { NotificationManager } from 'react-notifications';

import { getAzureB2CLoginLink } from '../../api/azureB2CService';

export const loginWithAzureB2C = async () => {
  const redirectTo = new URLSearchParams(window.location.search).get(
    'redirectTo',
  );

  try {
    const redirectLink = await getAzureB2CLoginLink({
      redirectTo,
    });
    window.location.href = redirectLink;
  } catch (error) {
    NotificationManager.error(
      <FormattedMessage
        id="generic.error.request.unknown"
        defaultMessage="An error has occurred. Try again later."
      />,
    );
  }
};
