import { isProdEnv } from '../api/config';

export const AUTH_ACCESS_TOKEN = 'AUTH_ACCESS_TOKEN';
export const IS_AZURE_B2C_AUTH = 'IS_AZURE_B2C_AUTH';
export const AZURE_B2C_TOKENS = 'AZURE_B2C_TOKENS';
export const AZURE_B2C_POLICY_NAME = 'AZURE_B2C_POLICY_NAME';
export const AZURE_B2C_CODE_VERIFIER = 'AZURE_B2C_CODE_VERIFIER';

export const getValueFromLocalStorage = key => {
  try {
    const value = localStorage.getItem(key);
    if (value !== null) {
      return JSON.parse(value);
    }
    return null;
  } catch (error) {
    return null;
  }
};

export const setValueInLocalStorage = (key, value) => {
  if (value !== null) {
    localStorage.setItem(key, JSON.stringify(value));
  }
};

export const deleteValueFromLocalStorage = key => {
  localStorage.removeItem(key);
};

export const putAuthTokens = tokens => {
  setValueInLocalStorage(
    isAzureB2CAuth() ? AZURE_B2C_TOKENS : AUTH_ACCESS_TOKEN,
    { ...tokens, issuedAt: new Date() },
  );
};

export const getAuthTokens = () => {
  return getValueFromLocalStorage(
    isAzureB2CAuth() ? AZURE_B2C_TOKENS : AUTH_ACCESS_TOKEN,
  );
};

export const deleteAuthTokens = () => {
  deleteValueFromLocalStorage(
    isAzureB2CAuth() ? AZURE_B2C_TOKENS : AUTH_ACCESS_TOKEN,
  );
  localStorage.removeItem(IS_AZURE_B2C_AUTH);
};

// TODO: Remove this functionality after migration to Azure B2C
export const setAzureB2CAuth = () =>
  setValueInLocalStorage(IS_AZURE_B2C_AUTH, true);

export const isAzureB2CAuth = () =>
  !!getValueFromLocalStorage(IS_AZURE_B2C_AUTH);

export const getCodeVerifierAndRemoveFromLocalStorage = () => {
  const codeVerifier = getValueFromLocalStorage(AZURE_B2C_CODE_VERIFIER);
  deleteValueFromLocalStorage(AZURE_B2C_CODE_VERIFIER);
  return codeVerifier;
};

export const getAzureB2CPolicyName = () => {
  const policyName = getValueFromLocalStorage(AZURE_B2C_POLICY_NAME);

  return policyName;
};

export const setAzureB2CPolicyName = name => {
  setValueInLocalStorage(AZURE_B2C_POLICY_NAME, name);
};

export const removeAzureB2CPolicyName = () => {
  deleteValueFromLocalStorage(AZURE_B2C_POLICY_NAME);
};
