import React from 'react';

import { Link } from 'react-router-dom';
import './styles.scss';

const Landing = () => (
  <div className="landing-page">
    <p className="landing-page--description">
      {`Depending on your needs and your application's target audience there are
      two API options to choose from. Both APIs provide similar tools for device management.`}
    </p>
    <ul>
      <li>
        <Link to="/documentation/intro?activeTab=intro">public API</Link> -
        enables myUplink Consumer scenarios allowing device owners to monitor
        and control the device.
      </li>
      <li>
        <Link to="/pro-documentation/intro?activeTab=intro">PRO API</Link> -
        enables myUplink PRO scenarios allowing installers and technicians to
        monitor and control devices for their customers.
      </li>
    </ul>
  </div>
);

export default Landing;
